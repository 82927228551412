/**

 ▬▬ι═══════ﺤ            -═══════ι▬▬
 Created by Chris on 22/08/18.
 ▬▬ι═══════ﺤ            -═══════ι▬▬

 **/

import React, { Component } from "react";
import { connect } from "react-redux";
import styles from "./Dashboard.module.scss";

class Dashboard extends Component {
    render() {
        return <div className={styles.dashboard_container}>Dashboard</div>;
    }
}

export default Dashboard = connect((state) => {
    return {};
})(Dashboard);
