import get from "./api/util/get";

export const SET_TAGS = "SET_TAGS";

export const getTags = () => {
    return async (dispatch) => {
        const result = await get("v1/tags");
        dispatch({
            type: SET_TAGS,
            items: result,
        });
        return result;
    };
};
