import React from "react";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import MenuIcon from "@material-ui/icons/Menu";
import Toolbar from "@material-ui/core/Toolbar";
import { withStyles } from "@material-ui/core/styles";
import filterRoutes from "../../../constants/filterRoutes";
import Routes from "../../../constants/routes/Routes";
import MenuItem from "@material-ui/core/MenuItem";
import ExitToApp from "@material-ui/icons/ExitToApp";
import DomainIcon from "@material-ui/icons/Domain";
import styles from "./DashboardWrapper.module.scss";
import { userLogOut } from "../../../redux/actions/user";
import { matchPath, withRouter } from "react-router";
import { connect } from "react-redux";
import isAdmin from "../../../constants/isAdmin";
import CampableLogo from "../../../resources/images/CampableLogo";
import { Divider } from "@material-ui/core";

const drawerWidth = 240;

const inlineStyles = (theme) => ({
    root: {
        display: "flex",
    },
    drawer: {
        [theme.breakpoints.up("md")]: {
            width: drawerWidth,
            flexShrink: 0,
        },
    },
    appBar: {
        backgroundColor: "white",
        zIndex: 1400,
    },
    menuButton: {
        marginRight: 20,
        [theme.breakpoints.up("md")]: {
            display: "none",
        },
    },
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
        backgroundColor: "#004d40",
        width: drawerWidth,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing.unit * 3,
    },
});

function DrawerItem(props) {
    const route = props.route;

    if (!!route.redirect || !!route.isHidden) {
        // noinspection JSConstructorReturnsPrimitive
        return null;
    }

    if (route.isAdmin) {
        if (!props.isAdmin) {
            // noinspection JSConstructorReturnsPrimitive
            return null;
        }
    }

    const Icon = route.icon;

    return (
        <MenuItem
            className={styles.menu_item}
            button
            selected={props.isSelected}
            onClick={() => props.onMenuItemPress(route)}>
            {Icon ? (
                <ListItemIcon>
                    <Icon style={{ color: "white" }} />
                </ListItemIcon>
            ) : null}
            <ListItemText primary={route.name} className={styles.menu_item_text} />
        </MenuItem>
    );
}

class DashboardWrapper extends React.Component {
    state = {
        mobileOpen: false,
    };

    onMenuItemPress = (route) => {
        this.props.history.push(route.path);
    };

    onLogOutPress = () => {
        this.props.dispatch(userLogOut());
    };

    renderRoute = (route, i) => (
        <DrawerItem
            key={route.path || i}
            isAdmin={this.props.isAdmin}
            route={route}
            isSelected={this.matchPath(route.path)}
            onMenuItemPress={this.onMenuItemPress}
        />
    );

    matchPath = (path) => {
        return !!(
            matchPath(this.props.location.pathname, {
                path: path,
                exact: true,
                strict: false,
            }) || {}
        ).isExact;
    };

    handleDrawerToggle = () => {
        this.setState((state) => ({ mobileOpen: !state.mobileOpen }));
    };

    render() {
        const { classes, theme } = this.props;

        const organisationRoutes = (this.props.user || { userRoles: [] }).userRoles.map((userRole) => {
            return {
                icon: DomainIcon,
                path: `/dashboard/organisations/${userRole.organisation}`,
                name: (this.props.organisations[userRole.organisation] || { name: userRole.organisation }).name,
            };
        });

        const drawer = (
            <div>
                <div className={classes.toolbar} />
                {filterRoutes(Routes, this.props.user).map(this.renderRoute)}
                <Divider className={styles.menu_divider} />
                {organisationRoutes.map(this.renderRoute)}
                {organisationRoutes.length > 0 ? <Divider className={styles.menu_divider} /> : <div />}
                <DrawerItem
                    key={"/logout"}
                    route={{ name: "Log out", icon: ExitToApp }}
                    onMenuItemPress={this.onLogOutPress}
                />
            </div>
        );

        return (
            <div className={classes.root}>
                <CssBaseline />
                <AppBar position="fixed" className={classes.appBar}>
                    <Toolbar>
                        <IconButton
                            // color="red"
                            aria-label="Open drawer"
                            onClick={this.handleDrawerToggle}
                            className={classes.menuButton}>
                            <MenuIcon />
                        </IconButton>
                        <CampableLogo className={styles.toolbar_logo} />
                    </Toolbar>
                </AppBar>
                <nav className={classes.drawer}>
                    <Hidden mdUp implementation="css">
                        <Drawer
                            container={this.props.container}
                            variant="temporary"
                            anchor={theme.direction === "rtl" ? "right" : "left"}
                            open={this.state.mobileOpen}
                            onClose={this.handleDrawerToggle}
                            classes={{
                                paper: classes.drawerPaper,
                            }}>
                            {drawer}
                        </Drawer>
                    </Hidden>
                    <Hidden smDown implementation="css">
                        <Drawer
                            classes={{
                                paper: classes.drawerPaper,
                            }}
                            variant="permanent"
                            open>
                            {drawer}
                        </Drawer>
                    </Hidden>
                </nav>
                <main className={classes.content}>
                    <div className={classes.toolbar} />
                    <div className={styles.dashboard_content}>{this.props.children}</div>
                </main>
            </div>
        );
    }
}

DashboardWrapper.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

// DashboardWrapper = withRouter(DashboardWrapper);

DashboardWrapper = connect((state) => {
    const user = state.user;
    return {
        user: user,
        isAdmin: isAdmin(user),
        organisations: state.organisations,
    };
})(DashboardWrapper);

export default withStyles(inlineStyles, { withTheme: true })(DashboardWrapper);
