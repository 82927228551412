import getToken from "./helpers/getAuthToken";
import get from "./api/util/get";

export const SET_DATA_PACK_INSTANCES = "SET_DATA_PACK_INSTANCES";

export const getDataPackInstances = (params) => {
    return async (dispatch) => {
        const token = await getToken();
        const result = await get("v1/data-pack-instances", token, params);
        dispatch({
            type: SET_DATA_PACK_INSTANCES,
            items: result.items,
        });
        return result;
    };
};
