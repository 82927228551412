/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 22/11/18.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import styles from "./Dialog.module.scss";
import classnames from "classnames";
import MatDialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Collapse from "@material-ui/core/Collapse/Collapse";
import Loader from "../../loader/Loader";
import uuid from "uuid/v4";
import Button from "../../button/Button";

export default class DialogV1 extends Component {
    static propTypes = {
        className: PropTypes.string,
        titleClassName: PropTypes.string,
        contentClassName: PropTypes.string,
        actionsClassName: PropTypes.string,

        shown: PropTypes.bool.isRequired,
        onClose: PropTypes.func.isRequired,
        onSubmit: PropTypes.func,
        loading: PropTypes.bool,

        error: PropTypes.string,
        title: PropTypes.string,
        text: PropTypes.string,
        actions: PropTypes.array,

        width: PropTypes.oneOf(["xs", "sm", "md", "lg", false]),
        fullWidth: PropTypes.bool,
    };

    static defaultProps = {
        className: null,
        titleClassName: null,
        contentClassName: null,
        actionsClassName: null,

        shown: false,
        onClose: () => null,
        onSubmit: () => null,
        loading: false,

        error: null,
        title: null,
        text: null,
        actions: [],

        width: "xs",
        fullWidth: true,
    };

    id = uuid();

    onClose = () => {
        if (!this.props.loading) {
            this.props.onClose();
        }
    };

    renderActionButton = (config, i) => {
        return (
            <Button key={i} onPress={config.onPress} {...config.props}>
                {config.text}
            </Button>
        );
    };

    render() {
        const className = classnames(styles.dialog, this.props.className);
        const titleClassName = classnames(styles.dialog_title, this.props.titleClassName);
        const contentClassName = classnames(styles.dialog_content, this.props.contentClassName);
        const actionsClassName = classnames(styles.dialog_actions, this.props.actionsClassName);

        const Wrapper = this.props.onSubmit ? "form" : Fragment;
        const wrapperProps = this.props.onSubmit ? { onSubmit: this.props.onSubmit } : {};

        return (
            <MatDialog
                open={this.props.shown}
                onClose={this.onClose}
                onBackdropClick={this.onClose}
                onEscapeKeyDown={this.onClose}
                classes={{
                    paper: className,
                }}
                aria-labelledby={this.id}>
                <Wrapper {...wrapperProps}>
                    {this.props.title ? (
                        <DialogTitle id={this.id} className={titleClassName}>
                            {this.props.title}
                        </DialogTitle>
                    ) : null}
                    <DialogContent className={contentClassName}>
                        <Collapse in={!this.props.loading}>
                            <Fragment>
                                {this.props.text ? (
                                    <DialogContentText className={styles.dialog_content_text}>
                                        {this.props.text}
                                    </DialogContentText>
                                ) : null}
                                {this.props.children}
                                {this.props.error ? (
                                    <DialogContentText className={styles.dialog_content_text_error}>
                                        {this.props.error}
                                    </DialogContentText>
                                ) : null}
                            </Fragment>
                        </Collapse>

                        <Collapse in={this.props.loading}>
                            <Loader />
                        </Collapse>
                    </DialogContent>

                    {(this.props.actions || []).length ? (
                        <DialogActions className={actionsClassName}>
                            {this.props.actions.map(this.renderActionButton)}
                        </DialogActions>
                    ) : null}
                </Wrapper>
            </MatDialog>
        );
    }
}
