/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 7/11/18.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

import uuid from "uuid/v4";
import createAction from "./helpers/createAction";

export const ADD_SNACKBAR = "ADD_SNACKBAR";
export const REMOVE_SNACKBAR = "REMOVE_SNACKBAR";

export const addSnackbar = createAction(ADD_SNACKBAR, "config");
export const removeSnackbar = createAction(REMOVE_SNACKBAR, "id");

export function showNotification(text, extraConfig = {}) {
    const config = {
        text: text,
        id: uuid(),
        ...extraConfig,
    };
    return (dispatch) => {
        dispatch(addSnackbar(config));
    };
}

export function showSuccess(text, extraConfig = {}) {
    return (dispatch) => {
        dispatch(showNotification(text, { success: true, ...extraConfig }));
    };
}

export function showWarning(text, extraConfig = {}) {
    return (dispatch) => {
        dispatch(showNotification(text, { warning: true, ...extraConfig }));
    };
}

export function showError(text, extraConfig = {}) {
    return (dispatch) => {
        dispatch(showNotification(text, { error: true, ...extraConfig }));
    };
}
