import React, { Component } from "react";
import { connect } from "react-redux";
import styles from "./ActiveDays.module.scss";
import { getActiveDays } from "../../../redux/actions/activeDays";
import Table from "../../widgets/table/Table";
import moment from "moment";
import DataTableCursorFooter from "../../../constants/tableFooters/DataTableCursorFooter";

class ActiveDays extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            rowsPerPage: 10,
            pageIndex: 0,
            page: {},
            nav: {},
            columns: [
                {
                    name: "ID",
                    options: {
                        display: false,
                    },
                },
                {
                    name: "Start Date",
                    options: {},
                },
                {
                    name: "Last Report",
                    options: {},
                },
                {
                    name: "Usage (MB)",
                    options: {},
                },
            ],
        };
    }

    componentDidMount() {
        this.getActiveDays();
    }

    renderTableData = () => {
        const page = this.state.page;
        const items = page.items || [];
        const activeDayIds = items.map((item) => item.id);
        const activeDays = activeDayIds.map((id) => this.props.store[id]);

        return activeDays.map((activeDay) => {
            return [
                activeDay.id,
                moment(activeDay.startDate).format(),
                moment(activeDay.latestUsageReportDate).format(),
                Math.round(activeDay.usage / (1024 * 1024)),
            ];
        });
    };

    getActiveDays = async () => {
        this.setState({
            loading: true,
        });
        try {
            const params = {
                cursor: this.state.nav.cursor,
                limit: this.state.rowsPerPage,
                order: "-startDate",
            };
            const apId = Number(this.props.match.params.apId);
            if (apId) {
                params.accessPoint = apId;
            }
            const page = await this.props.dispatch(getActiveDays(params));
            console.log("page", page);
            this.setState({
                loading: false,
                page: page,
            });
        } catch (err) {
            this.setState({
                loading: false,
                error: err.message,
            });
        }
    };

    onChangePage = (pageIndex) => {
        if (this.state.loading) {
            return;
        }
        this.setState((prevState) => {
            let nav;
            if (pageIndex > prevState.pageIndex) {
                nav = {
                    prevNav: prevState.nav,
                    cursor: prevState.page.cursor,
                };
            } else {
                nav = prevState.nav.prevNav;
            }
            return {
                nav: nav,
                pageIndex: pageIndex,
                loading: true,
            };
        }, this.getActiveDays);
    };

    onChangeRowsPerPage = (rowsPerPage) => {
        this.setState(
            {
                rowsPerPage: rowsPerPage,
            },
            this.getActiveDays,
        );
    };

    onColumnViewChange = (changedColumn, action) => {
        this.setState((prevState) => {
            const columns = [...prevState.columns];
            const i = columns.findIndex((col) => col.name === changedColumn);
            const column = columns[i];
            columns[i] = {
                ...column,
                options: {
                    ...column.options,
                    display: action === "add",
                },
            };
            return { columns: columns };
        }, this.getActiveDays);
    };

    render() {
        const data = this.renderTableData();
        const count = this.state.pageIndex * this.state.rowsPerPage + data.length;
        const end = !this.state.page.cursor;
        const options = {
            filter: false,
            responsive: "stacked",
            serverSide: true,
            sort: false,
            search: false,
            download: false,
            count: count,
            page: this.state.pageIndex,
            rowsPerPage: this.state.rowsPerPage,
            customFooter: DataTableCursorFooter(end),
            onChangeRowsPerPage: this.onChangeRowsPerPage,
            onChangePage: this.onChangePage,
            onColumnViewChange: this.onColumnViewChange,
        };
        return (
            <div className={styles.root}>
                <Table
                    options={options}
                    loading={this.state.loading}
                    title={"Active Days"}
                    data={this.renderTableData()}
                    columns={this.state.columns}
                />
            </div>
        );
    }
}

export default ActiveDays = connect((state, props) => {
    return {
        store: state.activeDays,
    };
})(ActiveDays);
