import * as Type from "../actions/tags";

const initialState = {};

export default function tags(state = initialState, action) {
    switch (action.type) {
        case Type.SET_TAGS: {
            return {
                items: action.items,
            };
        }

        default:
            return state;
    }
}
