/**

 ▬▬ι═══════ﺤ            -═══════ι▬▬
 Created by Chris on 22/08/18.
 ▬▬ι═══════ﺤ            -═══════ι▬▬

 **/

import React from "react";

import PersonIcon from "@material-ui/icons/Person";
import WifiIcon from "@material-ui/icons/StayCurrentLandscape";
import DomainIcon from "@material-ui/icons/Domain";
import DashboardIcon from "@material-ui/icons/Dashboard";
import CollectionsIcon from "@material-ui/icons/Collections";
import LocationIcon from "@material-ui/icons/LocationOn";
import Dashboard from "../../components/routes/dashboard/Dashboard";
import AccessPoints from "../../components/routes/accessPoints/AccessPoints";
import Organisations from "../../components/routes/organisations/Organisations";
import Users from "../../components/routes/users/Users";
import Collections from "../../components/routes/collections/Collections";
import Locations from "../../components/routes/locations/Locations";
import OrganisationRoutes from "./OrganisationRoutes";
import AccessPointRoutes from "./AccessPointRoutes";
import LocationRoutes from "./LocationRoutes";
import WifiJourneyRoutes from "./WifiJourneyRoutes";
import Organisation from "../../components/routes/organisation/Organisation";

//only lazy load stuff that can't be accessed from the side menu
const AccessPoint = React.lazy(() => import("../../components/routes/accessPoint/AccessPoint"));
const Collection = React.lazy(() => import("../../components/routes/organisation/routes/collection/Collection"));
const User = React.lazy(() => import("../../components/routes/user/User"));
const WifiJourney = React.lazy(() => import("../../components/routes/wifiJourney/WifiJourney"));
const Location = React.lazy(() => import("../../components/routes/location/Location"));
const Site = React.lazy(() => import("../../components/routes/site/Site"));
const SiteAvailabilityRule = React.lazy(() =>
    import("../../components/routes/site/routes/availability/SiteAvailabilityRule"),
);

export const ORGANISATION_PATH = "/dashboard/organisations/:orgId";
export const SITE_PATH = "/dashboard/locations/:locationId/sites/:siteId";
export const SITE_AVAILABILITY_RULE_PATH = "/dashboard/locations/:locationId/sites/:siteId/availability/:ruleIndex";

const Routes = [
    {
        path: "/",
        component: Users,
        name: "Home",
        exact: true,
        isHidden: true,
    },
    {
        path: "/dashboard",
        component: Dashboard,
        icon: DashboardIcon,
        name: "Dashboard",
        exact: true,
        isHidden: true,
    },
    {
        path: "/dashboard/users",
        component: Users,
        icon: PersonIcon,
        name: "Users",
        isAdmin: true,
        exact: true,
    },
    {
        path: "/dashboard/users/:userId",
        component: User,
        isAdmin: true,
        isHidden: true,
    },
    {
        path: "/dashboard/locations",
        component: Locations,
        icon: LocationIcon,
        name: "Locations",
        exact: true,
        isAdmin: true,
    },
    {
        path: "/dashboard/locations/:locationId",
        component: Location,
        isHidden: true,
        routes: LocationRoutes,
        exact: true,
    },
    {
        path: "/dashboard/locations/:locationId/sites",
        component: Location,
        isHidden: true,
        routes: LocationRoutes,
        exact: true,
    },
    {
        path: "/dashboard/locations/:locationId/bookings",
        component: Location,
        isHidden: true,
        routes: LocationRoutes,
        exact: true,
    },
    {
        path: SITE_PATH,
        component: Site,
        isHidden: true,
        exact: true,
    },
    {
        path: SITE_PATH + "/availability",
        component: Site,
        isHidden: true,
        exact: true,
    },
    {
        path: SITE_PATH + "/availability/:ruleIndex",
        component: SiteAvailabilityRule,
        isHidden: true,
    },
    {
        path: "/dashboard/access-points",
        component: AccessPoints,
        icon: WifiIcon,
        name: "Access Points",
        exact: true,
        isAdmin: true,
    },
    {
        path: "/dashboard/access-points/:apId",
        component: AccessPoint,
        isHidden: true,
        routes: AccessPointRoutes,
    },
    {
        path: "/dashboard/wifi-journeys/:wjId",
        component: WifiJourney,
        isHidden: true,
        routes: WifiJourneyRoutes,
    },
    {
        path: "/dashboard/organisations",
        component: Organisations,
        icon: DomainIcon,
        name: "Organisations",
        exact: true,
        isAdmin: true,
    },
    {
        path: ORGANISATION_PATH,
        component: Organisation,
        isHidden: true,
        routes: OrganisationRoutes,
    },
    {
        path: "/dashboard/collections",
        component: Collections,
        icon: CollectionsIcon,
        name: "Collections",
        exact: true,
        isAdmin: true,
        isHidden: true,
    },
    {
        path: "/dashboard/collections/:collectionId",
        component: Collection,
        icon: CollectionsIcon,
        isHidden: true,
    },
];

export default Routes;
