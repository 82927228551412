import React, { Component } from "react";
import { connect } from "react-redux";
import styles from "./WifiJourney.module.scss";
import { getWifiJourney } from "../../../redux/actions/wifiJourneys";
import { getOrganisation } from "../../../redux/actions/organisations";
import TextField from "@material-ui/core/TextField";
import { endWifiJourney } from "../../../redux/actions/wifiJourneys";
import Loader from "../../widgets/loader/Loader";
import { Link } from "react-router-dom";
import Button from "../../widgets/button/Button";
import Chip from "../../widgets/chip/Chip";
import { InputLabel } from "@material-ui/core";

class WifiJourneyHome extends Component {
    constructor(props) {
        super(props);

        this.state = {};
    }

    componentDidMount = () => {
        this.getWifiJourney();
        this.getOrganisation();
    };

    componentDidUpdate = (prevProps) => {
        const prevOrgId = (prevProps.wifiJourney || {}).organisation;
        const newOrgId = (this.props.wifiJourney || {}).organisation;
        if (prevOrgId !== newOrgId) {
            this.getOrganisation();
        }
    };

    getWifiJourney = async () => {
        if (!this.props.wjId) {
            return;
        }
        this.setState({
            wjLoading: true,
        });
        try {
            await this.props.dispatch(getWifiJourney(this.props.wjId));
            this.setState({
                wjLoading: false,
            });
        } catch (err) {
            this.setState({
                wjLoading: false,
                error: err.message,
            });
        }
    };

    getOrganisation = async () => {
        if (!this.props.wifiJourney) {
            return;
        }
        this.setState({
            orgLoading: true,
        });
        try {
            await this.props.dispatch(getOrganisation(this.props.wifiJourney.organisation));
            this.setState({
                orgLoading: false,
            });
        } catch (err) {
            this.setState({
                orgLoading: false,
                error: err.message,
            });
        }
    };

    endWifiJourney = async () => {
        this.setState({
            journeyStatusLoading: true,
            error: null,
        });
        try {
            await this.props.dispatch(endWifiJourney(this.props.wifiJourney.accessPoint));
            this.getWifiJourney();
        } catch (err) {
            this.setState({
                journeyStatusLoading: false,
                error: err.message,
            });
        }
    };

    render() {
        const organisation = this.props.organisation || {};
        const journey = this.props.wifiJourney || {};

        const loading = this.state.wjLoading || this.state.orgLoading;
        const isActive = journey.startDate && !journey.endDate;

        const statusWidget = (
            <div className={styles.status_field_container}>
                <InputLabel>Status</InputLabel>
                <div className={styles.status_container}>
                    <Chip className={styles.status} green={isActive} red={!isActive}>
                        {isActive ? "ACTIVE" : "INACTIVE"}
                    </Chip>
                    {isActive ? (
                        <Button loading={!!this.state.journeyStatusLoading} onPress={this.endWifiJourney}>
                            Stop
                        </Button>
                    ) : (
                        <span />
                    )}
                </div>
            </div>
        );

        return loading ? (
            <Loader />
        ) : (
            <div>
                <div>
                    <span>Organisation: </span>
                    <Link to={`/dashboard/organisations/${journey.organisation}`}>{organisation.name}</Link>
                </div>
                <br />
                <div>
                    <span>Access Point: </span>
                    <Link to={`/dashboard/access-points/${journey.accessPoint}`}>{journey.accessPoint}</Link>
                </div>
                <TextField
                    label="Start Date"
                    value={journey.startDate || ""}
                    margin="normal"
                    className={styles.text_input}
                    InputProps={{
                        readOnly: true,
                    }}
                />
                <TextField
                    label="Estimated End Date"
                    value={journey.estEndDate || ""}
                    margin="normal"
                    className={styles.text_input}
                    InputProps={{
                        readOnly: true,
                    }}
                />
                <TextField
                    label="End Date"
                    value={journey.endDate || ""}
                    margin="normal"
                    className={styles.text_input}
                    InputProps={{
                        readOnly: true,
                    }}
                />
                <TextField
                    label="Affiliate Booking ID"
                    value={journey.affiliateBookingId || ""}
                    margin="normal"
                    className={styles.text_input}
                    InputProps={{
                        readOnly: true,
                    }}
                />
                <p />
                {statusWidget}
                <div className={styles.error}>{this.state.error}</div>
            </div>
        );
    }
}

export default WifiJourneyHome = connect((state, props) => {
    const wjId = Number(props.match.params.wjId);
    const wifiJourney = state.wifiJourneys[wjId];
    const organisation = state.organisations[(wifiJourney || {}).organisation];
    return {
        wjId: wjId,
        wifiJourney: wifiJourney,
        organisation: organisation,
    };
})(WifiJourneyHome);
