/**

 ▬▬ι═══════ﺤ            -═══════ι▬▬
 Created by Chris on 27/08/18.
 ▬▬ι═══════ﺤ            -═══════ι▬▬

 **/

import * as Type from "../actions/campaigns";
import uniq from "lodash/uniq";

const initialState = {
    items: {},
    collections: {},
};

export default function campaigns(state = initialState, action) {
    switch (action.type) {
        case Type.SET_CAMPAIGNS: {
            const items = {
                ...state.items,
            };
            const collections = {
                ...state.collections,
            };

            (action.items || []).map((campaign) => {
                items[campaign.id] = campaign;
                collections[campaign.collection] = uniq([...(collections[campaign.collection] || []), campaign.id]);
                return null;
            });

            return {
                ...state,
                items: items,
                collections: collections,
            };
        }

        case Type.SET_CAMPAIGN: {
            const campaign = action.campaign || {};

            const collections = {
                ...state.collections,
            };

            collections[campaign.collection] = uniq([...(collections[campaign.collection] || []), campaign.id]);

            return {
                ...state,
                items: {
                    ...state.items,
                    [campaign.id]: campaign,
                },
                collections: collections,
            };
        }
        default: {
            return state;
        }
    }
}
