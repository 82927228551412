/**
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 * Created by Chris on 9/04/18.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 **/

import values from "lodash/values";
import getToken from "./getAuthToken";

function callFunc(func, ...params) {
    if (!!func && typeof func === "function") {
        func(...params);
    }
}

export default function wrapper(promise, onDispatchSuccess, onDispatchFailure) {
    return ({ onSuccess, onError, onSuccessOrError, ...params } = {}) => {
        return async (dispatch, getState) => {
            try {
                const token = await getToken();
                const data = await promise(token, ...values(params));

                if (process.env.NODE_ENV === "development") {
                    console.log("API SUCCESS: ", data);
                }

                callFunc(onDispatchSuccess, dispatch, data, ...Object.values(params), getState);
                callFunc(onSuccess, data, ...Object.values(params));
                callFunc(onSuccessOrError, data, ...Object.values(params));
            } catch (error) {
                if (error) {
                    if (process.env.NODE_ENV === "development") {
                        console.log("API FAILURE: ", error);
                    }
                    callFunc(onDispatchFailure, dispatch, error, ...Object.values(params), getState);
                    callFunc(onError, error, ...Object.values(params));
                    callFunc(onSuccessOrError, error, ...Object.values(params));
                }
            }
        };
    };
}
