/**

 ▬▬ι═══════ﺤ            -═══════ι▬▬
 Created by Chris on 24/08/18.
 ▬▬ι═══════ﺤ            -═══════ι▬▬

 **/

import * as Types from "../actions/user";

const initialState = null;

export default (state = initialState, action) => {
    switch (action.type) {
        case Types.SET_USER: {
            return action.user;
        }

        case Types.REMOVE_USER: {
            return null;
        }

        default: {
            return state;
        }
    }
};
