/**

 ▬▬ι═══════ﺤ            -═══════ι▬▬
 Created by Chris on 24/08/18.
 ▬▬ι═══════ﺤ            -═══════ι▬▬

 **/

import React from "react";
import PropTypes from "prop-types";
import styles from "./Error.module.scss";
import classnames from "classnames";

const Error = (props) => {
    const className = classnames(styles.error, props.className, props.classes);
    const error = (props.meta || {}).error || props.error;

    return error ? (
        <div className={className}>
            <span>{error}</span>
        </div>
    ) : null;
};

Error.propTypes = {
    className: PropTypes.string,
    classes: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    error: PropTypes.string,
};

Error.defaultProps = {
    className: null,
    classes: [],
    error: null,
};

export default Error;
