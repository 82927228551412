import React from "react";
import Field from "redux-form/es/Field";
import CheckBoxMultiSelect from "./CheckBoxMultiSelect";

const CheckBoxMultiSelectField = (props) => <Field {...props} component={CheckBoxMultiSelect} />;

CheckBoxMultiSelectField.propTypes = CheckBoxMultiSelect.propTypes;
CheckBoxMultiSelectField.defaultProps = CheckBoxMultiSelect.defaultProps;

export default CheckBoxMultiSelectField;
