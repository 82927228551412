/**

 ▬▬ι═══════ﺤ            -═══════ι▬▬
 Created by Chris on 27/08/18.
 ▬▬ι═══════ﺤ            -═══════ι▬▬

 **/

import * as Type from "../actions/locations";

const initialState = {};

export default function locations(state = initialState, action) {
    switch (action.type) {
        case Type.SET_LOCATION: {
            return {
                ...state,
                [action.location.id]: action.location,
            };
        }

        case Type.SET_LOCATIONS: {
            const newState = { ...state };
            action.items.forEach((item) => {
                newState[item.id] = item;
            });
            return newState;
        }

        default:
            return state;
    }
}
