import React, { Component } from "react";
import PropTypes from "prop-types";
import pin from "../../../resources/images/site-pin-no-shad.png";
import Map from "../../common/map/Map";
import Marker from "../../common/map/Marker";
import FormControl from "@material-ui/core/FormControl";
import classnames from "classnames";
import styles from "../select/Select.module.scss";

export default class GeoLocationMapSelect extends Component {
    static propTypes = {
        className: PropTypes.string,
        classes: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),

        latitude: PropTypes.object,
        longitude: PropTypes.object,
        native: PropTypes.bool,
    };

    static defaultProps = {
        className: null,
        classes: [],

        native: false,
    };

    constructor(props) {
        super(props);

        this.onDragEnd = this.onDragEnd.bind(this);
    }

    onDragEnd(event) {
        const lat = event.latLng.lat();
        const lng = event.latLng.lng();

        if (this.props.latitude.input.onChange) {
            this.props.latitude.input.onChange(lat);
        }
        if (this.props.longitude.input.onChange) {
            this.props.longitude.input.onChange(lng);
        }
    }

    render() {
        const className = classnames(styles.select, this.props.className, this.props.classes);

        let error = this.props.error;
        if (this.props.meta) {
            error = this.props.meta.touched && this.props.meta.error;
        }

        const lat = this.props.latitude.input.value || 0;
        const lng = this.props.longitude.input.value || 0;
        const center = { lat: lat, lng: lng };

        return (
            <FormControl className={className} error={!!error}>
                {/*<h4>Click and drag the pin to your desired location.</h4>*/}
                <Map
                    containerStyle={{ height: "35vh" }}
                    mapStyle={{ height: "35vh" }}
                    options={{
                        streetViewControl: false,
                        panControl: true,
                        mapTypeControl: true,
                        scrollwheel: false,
                        maxZoom: 18,
                        minZoom: 3,
                    }}
                    center={center}>
                    <Marker
                        lat={lat}
                        lng={lng}
                        src={pin}
                        onDragEnd={this.onDragEnd}
                        scaledSize={{ height: 55, width: 45 }}
                        draggable={true}
                    />
                </Map>
            </FormControl>
        );
    }
}
