/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 4/12/18.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import styles from "./CheckBox.module.scss";
import classnames from "classnames";
import MatCheckBox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";

class CheckBox extends Component {
    static propTypes = {
        className: PropTypes.string,

        label: PropTypes.string,
        value: PropTypes.bool,
    };

    static defaultProps = {
        className: null,

        label: null,
        value: false,
    };

    onChange = (e) => {
        const value = Boolean(e.target.checked);
        if (this.props.onTextChange) {
            this.props.onTextChange(value);
        }

        if (this.props.input) {
            this.props.input.onChange(value);
        }
    };

    render() {
        const className = classnames(styles.check_box, this.props.className);

        let value = this.props.value;
        if (this.props.input) {
            value = this.props.input.value;
        }

        return (
            <FormControlLabel
                className={className}
                control={
                    <MatCheckBox
                        checked={value}
                        onChange={this.onChange}
                        classes={{
                            root: styles.checkbox_root,
                            checked: styles.checkbox_inner,
                        }}
                    />
                }
                label={this.props.label}
            />
        );
    }
}

export default CheckBox = connect((state) => {
    return {};
})(CheckBox);
