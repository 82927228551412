/**

 ▬▬ι═══════ﺤ            -═══════ι▬▬
 Created by Chris on 21/07/17.
 ▬▬ι═══════ﺤ            -═══════ι▬▬

 **/

import fetchJSON from "./fetchJSON";

export default function get(url, token, params) {
    const qs = [];
    if (params) {
        for (const param in params) {
            if (params[param] != null) {
                const p = encodeURIComponent(param);
                let v;
                if (Object.prototype.toString.call(params[param]) === "[object Array]") {
                    v = encodeURIComponent(params[param].join(","));
                } else {
                    v = encodeURIComponent(params[param]);
                }

                qs.push(p + "=" + v);
            }
        }
    }

    let query = "";
    if (qs.length > 0) {
        query = "?" + qs.join("&");
    }

    return fetchJSON("get", url + query, token);
}
