/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 2018-12-12.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

import Redirect from "react-router/es/Redirect";
import Route from "react-router-dom/es/Route";
import React from "react";
import filterRoutes from "./filterRoutes";
import { store } from "../App";

export default function renderRoutes(routes = [], user = null, orgId = null) {
    if (!user) {
        user = store.getState().user;
    }

    return filterRoutes(routes, user, orgId).map((route) => {
        const Comp = route.component;
        return route.redirect ? (
            <Redirect path={route.path} to={route.to} key={route.path} />
        ) : (
            <Route path={route.path} render={(props) => <Comp {...props} />} exact={!!route.exact} key={route.path} />
        );
    });
}
