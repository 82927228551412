/**

 ▬▬ι═══════ﺤ            -═══════ι▬▬
 Created by Chris on 25/08/18.
 ▬▬ι═══════ﺤ            -═══════ι▬▬

 **/

import * as Type from "../actions/organisations";
import { omit } from 'lodash';

const initialState = {};

export default function organisations(
    state = initialState,
    action = { type: null, organisation: null, organisations: [] },
) {
    switch (action.type) {
        case Type.REMOVE_ORGANISATION: {
            return omit(state, action.orgId);
        }

        case Type.SET_ORGANISATIONS: {
            const newState = { ...state };
            (action.organisations || []).map((organisation) => {
                newState[organisation.id] = organisation;
                return null;
            });
            return newState;
        }

        case Type.SET_ORGANISATION: {
            const organisation = action.organisation || {};
            return {
                ...state,
                [organisation.id]: organisation,
            };
        }
        default: {
            return state;
        }
    }
}
