/**

 ▬▬ι═══════ﺤ            -═══════ι▬▬
 Created by Chris on 16/01/18.
 ▬▬ι═══════ﺤ            -═══════ι▬▬

 **/

const FORMATTERS = {
    //Default (e.g. NZD,AUD,USD,EUR,GBP): cents to dollars
    DEFAULT: (a, p, s) => (p ? `$${a / 100} ${s}` : a / 100),
    //China: no conversion
    RMB: (a) => a,
};

export default function fromLowestCurrencyFormatter(amount, currencyCode, pretty) {
    return (FORMATTERS[currencyCode] || FORMATTERS.DEFAULT)(amount, pretty, currencyCode);
}
