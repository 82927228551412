import * as Type from "../actions/accessPoints";

const initialState = {};

export default function accessPoints(state = initialState, action) {
    switch (action.type) {
        case Type.SET_ACCESS_POINT: {
            return {
                ...state,
                [action.accessPoint.id]: action.accessPoint,
            };
        }

        case Type.SET_ACCESS_POINTS: {
            const newState = { ...state };
            action.items.forEach((item) => {
                newState[item.id] = item;
            });
            return newState;
        }

        default:
            return state;
    }
}
