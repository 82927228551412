/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 22/11/18.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

import {Link} from "react-router-dom";
import React, {Component} from "react";
import {connect} from "react-redux";
import styles from "./AccessPoints.module.scss";
import {searchAccessPoints} from "../../../redux/actions/accessPoints";
import {getOrganisations} from "../../../redux/actions/organisations";
import Table from "../../widgets/table/Table";
import TableChip from "../../widgets/chip/TableChip";
import LinkButton from "../../widgets/button/LinkButton";
import {Icon, IconButton} from "@material-ui/core";
import EditAccessPointsDialog from "../../widgets/dialog/editAccessPointsDialog/EditAccessPointsDialog";
import CheckBox from "../../widgets/checkBox/CheckBox";
import DataTableCursorFooter from "../../../constants/tableFooters/DataTableCursorFooter";

class AccessPoints extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            rowsPerPage: 10,
            pageIndex: 0,
            page: {},
            nav: {},
            rowsSelected: [],
            columns: [
                {
                    key: "id",
                    name: "ID",
                    options: {
                        display: true,
                    },
                },
                {
                    name: "Organisation",
                    options: {
                        display: true,
                        customBodyRender: this.renderOrgCell,
                    },
                },
                {
                    name: "Region",
                    options: {
                        display: false,
                    },
                },
                {
                    name: "Time Zone",
                    options: {
                        display: false,
                    },
                },
                {
                    key: "label",
                    name: "Label",
                    options: {
                        display: true,
                    },
                },
                {
                    key: "iccid",
                    name: "ICCID",
                    options: {
                        display: false,
                    },
                },
                {
                    key: "bssid",
                    name: "BSSID",
                    options: {
                        display: false,
                    },
                },
                {
                    name: "Deep link",
                    options: {
                        display: false,
                    },
                },
                {
                    key: "ipAddress",
                    name: "IP address",
                    options: {
                        display: true,
                    },
                },
                {
                    key: "tags",
                    name: "Tags",
                    options: {
                        display: false,
                    },
                },
                {
                    name: "Wifi Journey",
                    options: {
                        display: true,
                        download: false,
                        customBodyRender: this.renderWifiJourneyRowItem,
                    },
                },
                {
                    name: "Status",
                    options: {
                        download: false,
                        display: true,
                        customBodyRender: this.renderStatusRowItem,
                    },
                },
                {
                    name: "",
                    options: {
                        display: true,
                        customBodyRender: this.renderRowEditButtons,
                    },
                },
            ],
        };
    }

    componentDidMount() {
        this.getOrganisations();
        this.getAccessPoints();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.orgId !== this.props.orgId
                || prevState.searchQuery !== this.state.searchQuery) {
            this.getAccessPoints();
        }
    }

    onSelectChange;

    renderSelectCell = (ap) => {
        return <CheckBox onChange={() => this.onSelectChange(ap)} value={ap.isSelected} />;
    };

    renderOrgCell = (orgId) => {
        const org = this.props.organisations[orgId] || {};
        return <Link to={`/dashboard/organisations/${orgId}`}>{org.name}</Link>;
    };

    getPageData = () => {
        const page = this.state.page;
        const items = page.items || [];
        const ids = items.map((item) => item.id);
        return ids.map((id) => this.props.store[id]);
    };

    renderTableData = () => {
        return this.getPageData().map((accessPoint) => {
            const label = accessPoint.label || {};
            const status = !accessPoint.status ? "blocked" : accessPoint.status;

            return [
                accessPoint.id,
                accessPoint.organisation,
                (accessPoint.region || {}).name,
                accessPoint.timeZone,
                label.deviceId || "",
                accessPoint.iccid,
                accessPoint.bssid,
                label.deepLink || "",
                accessPoint.ipAddress,
                accessPoint.tags.join(','),
                !!accessPoint.activeWifiJourney,
                status,
                accessPoint,
            ];
        });
    };

    getOrganisations = async () => {
        this.props.dispatch(getOrganisations());
    };

    getAccessPoints = async () => {
        const queryTime = new Date().getTime();
        this.setState({
            loading: true,
            lastApQuery: queryTime,
        });
        try {
            const params = {
                cursor: this.state.nav.cursor,
                limit: this.state.rowsPerPage,
            };
            const orgId = this.props.orgId;
            if (orgId) {
                params.org = orgId;
            }
            if (this.state.searchQuery) {
                params.query = this.state.searchQuery;
            }
            const page = await this.props.dispatch(searchAccessPoints(params));
            if(this.state.lastApQuery === queryTime) {
                this.setState({
                    loading: false,
                    page: page,
                });
            }
        } catch (err) {
            this.setState({
                loading: false,
                error: err.message,
            });
        }
    };

    renderStatusRowItem = (status) => {
        const green = status === "allowed";
        const red = status === "blocked";

        status = (status || "").toUpperCase();
        return (
            <TableChip green={green} red={red}>
                {status}
            </TableChip>
        );
    };

    renderWifiJourneyRowItem = (hasActiveJourney) => {
        return (
            <TableChip green={hasActiveJourney} red={!hasActiveJourney}>
                {hasActiveJourney ? "ACTIVE" : "INACTIVE"}
            </TableChip>
        );
    };

    renderRowEditButtons = (accessPoint) => {
        return (
            <div className={styles.edit_buttons}>
                <LinkButton to={`/dashboard/access-points/${accessPoint.id}`}>View</LinkButton>
            </div>
        );
    };

    onChangePage = (pageIndex) => {
        if (this.state.loading) {
            return;
        }
        this.setState((prevState) => {
            let nav;
            if (pageIndex > prevState.pageIndex) {
                nav = {
                    prevNav: prevState.nav,
                    cursor: prevState.page.cursor,
                };
            } else {
                nav = prevState.nav.prevNav;
            }
            return {
                nav: nav,
                pageIndex: pageIndex,
                loading: true,
            };
        }, this.getAccessPoints);
    };

    onChangeRowsPerPage = (rowsPerPage) => {
        this.setState(
            {
                rowsPerPage: rowsPerPage,
            },
            this.getAccessPoints,
        );
    };

    onColumnViewChange = (changedColumn, action) => {
        this.setState((prevState) => {
            const columns = [...prevState.columns];
            const i = columns.findIndex((col) => col.name === changedColumn);
            const column = columns[i];
            columns[i] = {
                ...column,
                options: {
                    ...column.options,
                    display: action === "add",
                },
            };
            return {
                columns: columns,
            };
        }, this.getAccessPoints);
    };

    onSearchChange = (searchQuery) => {
        this.setState({searchQuery: searchQuery});
    };

    renderSelectToolbar = () => {
        return (
            <IconButton className={styles.toolbar_button} onClick={this.onEditPress}>
                <Icon>edit</Icon>
            </IconButton>
        );
    };

    onRowsSelect = (_, allRowsSelected) => {
        const rowsSelected = allRowsSelected.map((x) => x.index);
        this.setState({
            rowsSelected: rowsSelected,
        });
    };

    onEditPress = () => {
        this.setState({
            editDialogShown: true,
        });
    };

    onEditDialogClose = () => {
        this.setState({
            editDialogShown: false,
        });
    };

    render() {
        const data = this.renderTableData();
        const count = this.state.pageIndex * this.state.rowsPerPage + data.length;
        const end = !this.state.page.cursor;
        const options = {
            filter: false,
            responsive: "stacked",
            serverSide: true,
            selectableRows: true,
            onRowsSelect: this.onRowsSelect,
            rowsSelected: this.state.rowsSelected,
            customToolbarSelect: this.renderSelectToolbar,
            customFooter: DataTableCursorFooter(end),
            sort: false,
            count: count,
            page: this.state.pageIndex,
            rowsPerPage: this.state.rowsPerPage,
            onChangeRowsPerPage: this.onChangeRowsPerPage,
            onChangePage: this.onChangePage,
            onSearchChange: this.onSearchChange,
            onColumnViewChange: this.onColumnViewChange,
        };

        const accessPoints = this.getPageData();
        const selectedAccessPoints = this.state.rowsSelected.map((i) => accessPoints[i]);

        return (
            <div className={styles.root}>
                <Table
                    options={options}
                    loading={this.state.loading}
                    title={"Access points"}
                    data={data}
                    columns={this.state.columns}
                />
                <EditAccessPointsDialog
                    shown={this.state.editDialogShown}
                    accessPoints={selectedAccessPoints}
                    onClose={this.onEditDialogClose}
                />
            </div>
        );
    }
}

export default AccessPoints = connect((state, props) => {
    return {
        orgId: Number(props.match.params.orgId) || null,
        store: state.accessPoints,
        organisations: state.organisations,
    };
})(AccessPoints);
