import { store } from "../App";
import isAdmin from "./isAdmin";

/**

 ▬▬ι═══════ﺤ            -═══════ι▬▬
 Created by Chris on 17/09/18.
 ▬▬ι═══════ﺤ            -═══════ι▬▬

 **/

export default function isOrgAdmin(orgId, user, force = false) {
    if (!user && !force) {
        user = store.getState().user;
    }

    return (
        isAdmin(user) ||
        (user || { userRoles: [] }).userRoles.some(
            (role) => role.organisation === orgId && role.role === "administrator",
        )
    );
}
