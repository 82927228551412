/**

 ▬▬ι═══════ﺤ            -═══════ι▬▬
 Created by Chris on 22/08/18.
 ▬▬ι═══════ﺤ            -═══════ι▬▬

 **/

import React, { Component } from "react";
import { connect } from "react-redux";
import styles from "./Users.module.scss";
import Table from "../../widgets/table/Table";
import { getUsers } from "../../../redux/actions/users";
import values from "lodash/values";
import LinkButton from "../../widgets/button/LinkButton";

class Users extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: "initial",
            searchText: null,
        };

        this.columns = [
            "Id",
            "Email",
            "Name",
            "Phone",
            {
                name: "",
                options: {
                    // customHeadRender: this.renderCreateNewButton,
                    customBodyRender: this.renderRowEditButtons,
                },
            },
        ];
    }

    componentDidMount = () => {
        this.getUsers();
    };

    getUsers = () => {
        this.setState({
            loading: true,
        });

        const params = {
            search: this.state.searchText,
        };

        this.props.dispatch(
            getUsers({
                params: params,
                onSuccess: () => {
                    this.setState({
                        loading: false,
                    });
                },
            }),
        );
    };

    renderRowEditButtons = (userId) => {
        return (
            <div className={styles.edit_buttons}>
                <LinkButton to={`/dashboard/users/${userId}`}>View</LinkButton>
            </div>
        );
    };

    onSearchChange = (searchText) => {
        this.setState(
            {
                searchText: searchText,
            },
            this.getUsers,
        );
    };

    renderTableData = () => {
        return this.props.users.map((user) => {
            return [
                user.id,
                user.email,
                user.firstName ? `${user.firstName} ${user.lastName}` : "",
                user.phone,
                user.id,
            ];
        });
    };

    render() {
        return (
            <div className={styles.users_container}>
                <Table
                    title={"Users"}
                    loading={!!this.state.loading}
                    data={this.renderTableData()}
                    columns={this.columns}
                    options={{
                        onSearchChange: this.onSearchChange,
                    }}
                />
            </div>
        );
    }
}

export default Users = connect((state) => {
    return {
        users: values(state.users.users),
    };
})(Users);
