import getToken from "./helpers/getAuthToken";
import get from "./api/util/get";

export const SET_ACTIVE_DAYS = "SET_ACTIVE_DAYS";

export const getActiveDays = (params) => {
    return async (dispatch) => {
        const token = await getToken();
        const result = await get("v1/active-days", token, params);
        dispatch({
            type: SET_ACTIVE_DAYS,
            items: result.items,
        });
        return result;
    };
};
