/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 11/11/18.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */
import getToken from "./helpers/getAuthToken";
import get from "./api/util/get";

export const SET_BOOKING = "SET_BOOKING";
export const SET_BOOKINGS = "SET_BOOKINGS";

export const getBookings = (params) => {
    return async (dispatch) => {
        const token = await getToken();
        const result = await get("v1/bookings", token, params);
        dispatch({
            type: SET_BOOKINGS,
            bookings: result.items,
        });
        return result;
    };
};

export const getGuestBookings = (userId) => {
    return async (dispatch) => {
        const token = await getToken();
        const result = await get(`v1/admin/bookings/guest/${userId}`, token);
        dispatch({
            type: SET_BOOKINGS,
            bookings: result.items,
        });
        return result;
    };
};
