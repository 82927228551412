import React, {Component} from "react";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {reduxForm, reset} from "redux-form";
import SelectField from "../../select/SelectField";
import {updateAccessPoints} from "../../../../redux/actions/accessPoints";
import DialogV1 from "../v1/DialogV1";
import _values from "lodash/values";
import _isEqual from "lodash/isEqual";
import ChipInputField from "../../chipInput/ChipInputField";
import {getCommsPlans, getJasperDevice, updateJasperDevice} from "../../../../redux/actions/jasper";

const INITIAL_STATE = {
    loading: false,
    error: null,
};

class EditAccessPointsDialog extends Component {
    static propTypes = {
        className: PropTypes.string,
        classes: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),

        shown: PropTypes.bool.isRequired,
        onClose: PropTypes.func.isRequired,
    };

    static defaultProps = {
        className: null,
        classes: [],

        shown: false,
        onClose: () => null,
    };

    state = INITIAL_STATE;

    componentDidMount() {
        this.props.dispatch(getCommsPlans());
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.accessPoints !== prevProps.accessPoints) {
            this.getJasperDevices();
        }
    }

    getJasperDevices = async () => {
        if(!this.props.accessPoints) {
            return;
        }
        return Promise.all(this.props.accessPoints.map(this.getDevice));
    }

    getDevice = async (ap) => {
        const iccid = ap.iccid.replace('F','');
        const device = this.props.jasperDevices[iccid];
        if(device) {
            return;
        }
        return this.props.dispatch(getJasperDevice(ap.iccid));
    }

    onSubmit = async (values, props) => {
        if(!this.props.isDirty) {
            return;
        }
        this.setState({
            loading: true,
        });
        try {
            let apTask = this.updateAccessPoints(values, props);
            let jasperTask = this.updateJasperDevices(values, props);
            await apTask;
            await jasperTask;
            this.setState({loading: false}, this.onClose);
        } catch (err) {
            this.setState({
                loading: false,
                error: err.message,
            });
        }
    }

    updateJasperDevices = async () => {
        if(!this.props.commsPlanIsDirty) {
            return;
        }
        let payload = {
            communicationPlan: this.props.selectedCommsPlan,
        };
        return Promise.all(this.props.accessPoints.map(ap =>
            this.props.dispatch(updateJasperDevice(ap.iccid, payload))));
    }

    updateAccessPoints = async () => {
        const organisation = this.props.selectedOrgId;
        const region = this.props.selectedRegionId;
        const timeZone = this.props.selectedTimeZone;
        const tags = this.props.selectedTags;

        let payload = this.props.accessPoints.map(ap => ({
            ...ap,
        }));

        if(this.props.orgIsDirty) {
            payload = payload.map(x => ({...x, organisation: organisation}));
        }

        if(this.props.regionIsDirty) {
            payload = payload.map(x => ({...x, region: { id: region }}));
        }

        if(this.props.timeZoneIsDirty) {
            payload = payload.map(x => ({...x, timeZone: timeZone}));
        }

        if(this.props.tagsIsDirty) {
            payload = payload.map(x => ({...x, tags: tags}));
        }

        const isApDirty = this.props.orgIsDirty
            || this.props.regionIsDirty
            || this.props.timeZoneIsDirty
            || this.props.tagsIsDirty;

        if(isApDirty) {
            return this.props.dispatch(updateAccessPoints(payload));
        }
    };

    onClose = () => {
        this.setState(INITIAL_STATE);
        this.props.onClose();
        this.props.dispatch(reset(this.props.form));
    };

    render() {
        const actions = [
            {
                text: "Cancel",
                onPress: this.onClose,
                props: {
                    plain: true,
                },
            },
            {
                text: "Submit",
                props: {
                    type: "submit",
                    loading: this.state.loading,
                    disabled: !this.props.isDirty,
                },
            },
        ];

        const aps = this.props.accessPoints || [];

        const orgOptions = this.props.organisations.map((o) => ({ value: o.id, label: o.name })).sort((a, b) => (a.label > b.label) ? 1 : -1);
        const regionOptions = this.props.regions.map((r) => ({ value: r.id, label: r.name }));
        const timeZoneOptions = this.props.timeZones.map((tz) => ({ value: tz, label: tz }));
        const commsPlanOptions = this.props.commsPlans.map((x) => ({ value: x, label: x }));

        regionOptions.unshift({ value: null });
        timeZoneOptions.unshift({ value: null });

        return (
            <DialogV1
                shown={this.props.shown}
                onClose={this.onClose}
                actions={actions}
                error={this.state.error}
                title={"Edit Access Point" + (aps.length > 1 ? "s" : "")}
                onSubmit={this.props.handleSubmit(this.onSubmit)}>
                <p />
                <SelectField name={"organisation"} label={"Organisation"} options={orgOptions} native />
                <SelectField name={"region"} label={"Region"} options={regionOptions} native />
                <SelectField name={"timeZone"} label={"Time Zone"} options={timeZoneOptions} native />
                <ChipInputField name={"tags"} label={"Tags"} />
                <SelectField name={"commsPlan"} label={"Comms Plan"} options={commsPlanOptions} native />
            </DialogV1>
        );
    }
}

const validate = (values) => {
    return {
        organisation: (!values.organisation || values.organisation === "") && "Required",
    };
};

const getInitialValues = (accessPoints, jasperDevices) => {
    accessPoints = accessPoints || [];
    accessPoints = accessPoints.map((ap) => ({
        ...ap,
        region: (ap.region || {}).id,
    }));
    accessPoints = accessPoints.length === 0 ? [{}] : accessPoints;
    jasperDevices = jasperDevices.length === 0 ? [{}] : jasperDevices;

    return accessPoints.map(ap => {
        const iccid = ap.iccid && ap.iccid.replace('F','');
        const device = jasperDevices[iccid] || {};
        const commsPlan = device.communicationPlan;
        return {
            ...ap,
            commsPlan,
        }
    }).reduce((result, item) => ({
        organisation: result.organisation === item.organisation ? item.organisation : undefined,
        region: result.region === item.region ? item.region : undefined,
        timeZone: result.timeZone === item.timeZone ? item.timeZone : undefined,
        tags: _isEqual((result.tags || []).sort(), item.tags.sort()) ? item.tags : undefined,
        commsPlan: result.commsPlan === item.commsPlan ? item.commsPlan : undefined,
    }));
};

EditAccessPointsDialog = reduxForm({
    form: "editAccessPoints",
    enableReinitialize: true,
    validate,
})(EditAccessPointsDialog);

EditAccessPointsDialog = connect((state, props) => {
    const jasperDevices = state.jasper.devices || {};
    const initialValues = getInitialValues(props.accessPoints, jasperDevices);
    const form = state.form.editAccessPoints || {};
    const values = form.values || {};
    const organisations = _values(state.organisations);
    const org = state.organisations[values.organisation] || {};
    const regions = org.regions || [];
    const region = regions.find((r) => r.id === values.region) || {};
    const timeZones = region.timeZones || [];
    const commsPlans = state.jasper.commsPlans || [];

    const selectedOrgId = values.organisation;
    const selectedRegionId = values.region;
    const selectedTimeZone = timeZones.includes(values.timeZone) ? values.timeZone : undefined;
    const selectedTags = values.tags;
    const selectedCommsPlan = values.commsPlan;

    const orgIsDirty = selectedOrgId !== initialValues.organisation;
    const regionIsDirty = selectedRegionId !== initialValues.region;
    const timeZoneIsDirty = selectedTimeZone !== initialValues.timeZone;
    const tagsIsDirty = selectedTags !== initialValues.tags;
    const commsPlanIsDirty = selectedCommsPlan !== initialValues.commsPlan;

    const isDirty = orgIsDirty
        || regionIsDirty
        || timeZoneIsDirty
        || tagsIsDirty
        || commsPlanIsDirty;

    return {
        organisations,
        initialValues,
        selectedTags,
        selectedOrgId,
        selectedRegionId,
        selectedTimeZone,
        selectedCommsPlan,
        regions,
        timeZones,
        commsPlans,
        jasperDevices,
        orgIsDirty,
        regionIsDirty,
        timeZoneIsDirty,
        tagsIsDirty,
        commsPlanIsDirty,
        isDirty,
    };
})(EditAccessPointsDialog);

export default EditAccessPointsDialog;
