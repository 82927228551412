/**

 ▬▬ι═══════ﺤ            -═══════ι▬▬
 Created by Chris on 22/08/18.
 ▬▬ι═══════ﺤ            -═══════ι▬▬

 **/

import { applyMiddleware, compose, createStore } from "redux";
import thunk from "redux-thunk";
import { persistStore } from "redux-persist";
import main from "../reducers/main";

const composeEnhancers =
    process.env.NODE_ENV === "development" && !!window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
        ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
        : compose;

export default function configureStore() {
    const store = createStore(main, composeEnhancers(applyMiddleware(thunk)));

    const persistor = persistStore(store);

    return {
        persistor: persistor,
        store: store,
    };
}
