import storage from "redux-persist/lib/storage";
import {persistCombineReducers} from "redux-persist";
import commsPlans from "./jasper/commsPlans";
import devices from "./jasper/devices";

const config = {
    key: "jasper",
    storage: storage,
    whitelist: [""],
    debug: process.env.REACT_APP_ENV === "test",
};

export default persistCombineReducers(config, {
    commsPlans: commsPlans,
    devices: devices,
});
