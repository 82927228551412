/**

 ▬▬ι═══════ﺤ            -═══════ι▬▬
 Created by Chris on 25/08/18.
 ▬▬ι═══════ﺤ            -═══════ι▬▬

 **/

import * as Type from "../actions/regions";

const initialState = {};

export default function regions(state = initialState, action = { type: null, region: null, regions: [] }) {
    switch (action.type) {
        case Type.SET_REGIONS: {
            const newState = { ...state };
            (action.regions || []).map((region) => {
                newState[region.id] = region;
                return null;
            });
            return newState;
        }
        default: {
            return state;
        }
    }
}
