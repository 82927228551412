/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 22/11/18.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

import * as Type from "../actions/dataPacks";

const initialState = {};

export default function dataPacks(state = initialState, action = { type: null, dataPacks: [], dataPack: null }) {
    switch (action.type) {
        case Type.SET_DATA_PACKS: {
            const newState = { ...state };

            action.dataPacks.map((dataPack) => {
                newState[dataPack.organisation] = {
                    ...(newState[dataPack.organisation] || {}),
                    [dataPack.id]: dataPack,
                };
                return null;
            });

            return newState;
        }

        case Type.SET_DATA_PACK: {
            const dataPack = action.dataPack;

            const org = state[dataPack.organisation] || {};
            org[dataPack.id] = dataPack;

            return {
                ...state,
                [dataPack.organisation]: {
                    ...org,
                },
            };
        }

        case Type.REMOVE_DATA_PACK: {
            const org = state[action.orgId] || {};
            delete org[action.dataPackId];

            return {
                ...state,
                [action.orgId]: org,
            };
        }

        default:
            return state;
    }
}
