/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 2019-06-06.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

import PropTypes from "prop-types";
import React, {Component} from "react";
import {SingleDatePicker as RD} from "react-dates";
import uuid from "uuid/v4";
import styles from "./DatePicker.module.scss";
import moment from "moment";

class DatePicker extends Component {
    constructor(props) {
        super(props);

        this.state = {
            date: props.initialDate,
            focused: null,
        };

        this.id = uuid();
    }

    onFocusChange = ({ focused }) => {
        this.props.onFocusChange(focused);
        this.setState({
            focused: focused,
        });
    };

    onDateChange = (date) => {
        this.props.onDateChange(date);
        this.setState({
            date: date,
        });

        if (this.props.input) {
            this.props.input.onChange(date.format('YYYY-MM-DD'));
        }
    };

    isOutsideRange = (date) => {
        return this.props.isOutsideRange(date);
    };

    render() {
        let date = this.props.date || this.state.date;
        if (this.props.input) {
            date = this.props.input.value;
        }

        if (date) {
            date = moment(date);
        }

        return (
            <div className={styles.date_picker_wrapper}>
                <label>{this.props.label}</label>
                <div className={styles.date_picker}>
                    <RD
                        date={date}
                        id={this.id}
                        onDateChange={this.onDateChange}
                        focused={this.state.focused}
                        onFocusChange={this.onFocusChange}
                        displayFormat={"DD/MM/YYYY"}
                        numberOfMonths={1}
                        noBorder
                        hideKeyboardShortcutsPanel
                        isOutsideRange={this.isOutsideRange}
                        daySize={39}
                    />
                </div>
            </div>
        );
    }
}

DatePicker.propTypes = {
    initialDate: PropTypes.any,
    date: PropTypes.any,

    onFocusChange: PropTypes.func,
    onDateChange: PropTypes.func,
    isOutsideRange: PropTypes.func,
};

DatePicker.defaultProps = {
    initialDate: null,
    date: null,

    onFocusChange: () => null,
    onDateChange: () => null,
    isOutsideRange: () => false,
};

export default DatePicker;
