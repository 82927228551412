/**

 ▬▬ι═══════ﺤ            -═══════ι▬▬
 Created by Chris on 25/08/18.
 ▬▬ι═══════ﺤ            -═══════ι▬▬

 **/

import React, { Component } from "react";
import { connect } from "react-redux";
import styles from "./Collections.module.scss";
import { getCollections } from "../../../redux/actions/collections";

class Collections extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
        };

        this.getCollections();
    }

    getCollections = () => {
        this.props.dispatch(getCollections({}));
    };

    onAddNewCollectionPress = () => {};

    render() {
        return <div className={styles.collections_container}></div>;
    }
}

export default Collections = connect((state) => {
    return {
        collections: state.collections.items,
    };
})(Collections);
