/**

 ▬▬ι═══════ﺤ            -═══════ι▬▬
 Created by Chris on 27/08/18.
 ▬▬ι═══════ﺤ            -═══════ι▬▬

 **/

import getToken from "./helpers/getAuthToken";
import get from "./api/util/get";
import put from "./api/util/put";

export const SET_LOCATION = "SET_LOCATION";
export const SET_LOCATIONS = "SET_LOCATIONS";

export const getLocations = (params) => {
    return async (dispatch) => {
        const token = await getToken();
        const result = await get("v1/locations", token, params);
        dispatch({
            type: SET_LOCATIONS,
            items: result.items,
        });
        return result;
    };
};

export const getLocation = (id) => {
    return async (dispatch) => {
        const token = await getToken();
        const result = await get(`v1/locations/${id}`, token);
        dispatch({
            type: SET_LOCATION,
            location: result,
        });
        return result;
    };
};

export const putLocation = (location) => {
    return async (dispatch) => {
        const token = await getToken();
        const result = await put(`v1/locations/${location.id}`, token, location);
        dispatch({
            type: SET_LOCATION,
            location: result,
        });
        return result;
    };
};
