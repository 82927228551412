/**

 ▬▬ι═══════ﺤ            -═══════ι▬▬
 Created by Chris on 24/08/18.
 ▬▬ι═══════ﺤ            -═══════ι▬▬

 **/

export default function createAction(type, ...argNames) {
    return function(...args) {
        const action = {
            type: type,
        };

        if (!!argNames && !!argNames.length) {
            argNames.forEach((arg, index) => {
                action[argNames[index]] = args[index];
            });
        }

        return action;
    };
}
