import React from "react";
import Dropzone from "react-dropzone";
import styles from "./SortableDndItem.module.scss";

export default class SortableDndItem extends React.Component {
    render() {
        return (
            <Dropzone onDrop={this.props.onDrop}>
                {({ getRootProps, getInputProps }) => {
                    return (
                        <div className={styles.dropzone} {...getRootProps()}>
                            <input {...getInputProps()} />
                            <h4>Add Images</h4>
                            <p>Drop images here, or click to select images to upload.</p>
                        </div>
                    );
                }}
            </Dropzone>
        );
    }
}
