/**

 ▬▬ι═══════ﺤ            -═══════ι▬▬
 Created by Chris on 6/09/18.
 ▬▬ι═══════ﺤ            -═══════ι▬▬

 **/

import React from "react";
import Field from "redux-form/es/Field";
import Select from "./Select";

const SelectField = (props) => <Field {...props} component={Select} />;

SelectField.propTypes = Select.propTypes;
SelectField.defaultProps = Select.defaultProps;

export default SelectField;
