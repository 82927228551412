import getToken from "../helpers/getAuthToken";
import get from "./util/get";
import put from "./util/put";

export const getCommsPlans = async () => {
    const token = await getToken();
    return get('v1/jasper-devices/communication-plans', token);
};

export const getJasperDevice = async (iccid) => {
    const token = await getToken();
    return get(`v1/jasper-devices/${iccid}`, token);
};

export const updateJasperDevice = async (iccid, payload) => {
    const token = await getToken();
    return put(`v1/jasper-devices/${iccid}`, token, payload);
};
