/**

 ▬▬ι═══════ﺤ            -═══════ι▬▬
 Created by Chris on 6/09/18.
 ▬▬ι═══════ﺤ            -═══════ι▬▬

 **/

import React, { Component } from "react";
import PropTypes from "prop-types";
import styles from "./Select.module.scss";
import classnames from "classnames";
import omit from "lodash/omit";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";

export default class Select extends Component {
    static propTypes = {
        className: PropTypes.string,
        classes: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),

        value: PropTypes.string,
        defaultValue: PropTypes.string,
        options: PropTypes.array,
        native: PropTypes.bool,
    };

    static defaultProps = {
        className: null,
        classes: [],

        value: "null",
        options: [],
        native: false,
    };

    state = {
        value: this.props.value,
    };

    onChange = (e) => {
        const value = e.target.value;
        if (this.props.onChange) {
            this.props.onChange(value);
        }
        if (this.props.input) {
            this.props.input.onChange(value);
        }
    };

    onFocus = () => {
        if (this.props.onFocus) {
            this.props.onFocus();
        }

        if (this.props.input) {
            this.props.input.onFocus();
        }
    };

    onBlur = () => {
        if (this.props.onBlur) {
            this.props.onBlur();
        }

        if (this.props.input) {
            this.props.input.onBlur();
        }
    };

    render() {
        const className = classnames(styles.select, this.props.className, this.props.classes);

        const props = omit({ ...this.props }, [...Object.keys(Select.defaultProps)]);

        let value = this.props.value;
        if (this.props.input) {
            value = this.props.input.value;
        }

        let error = this.props.error;
        if (this.props.meta) {
            error = this.props.meta.touched && this.props.meta.error;
        }

        return (
            <FormControl className={className} error={!!error}>
                <TextField {...props} select value={value} onChange={this.onChange}>
                    {this.props.options.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                </TextField>

                {error ? <FormHelperText id={error}>{error}</FormHelperText> : null}
            </FormControl>
        );
    }
}
