/**

 ▬▬ι═══════ﺤ            -═══════ι▬▬
 Created by Chris on 25/08/18.
 ▬▬ι═══════ﺤ            -═══════ι▬▬

 **/

import React, { Component, Suspense } from "react";
import { connect } from "react-redux";
import styles from "./Organisation.module.scss";
import { getOrganisation } from "../../../redux/actions/organisations";
import isAdmin from "../../../constants/isAdmin";
import Switch from "react-router/es/Switch";
import { matchPath } from "react-router";
import Paper from "@material-ui/core/Paper/Paper";
import MenuItem from "@material-ui/core/MenuItem";
import OrganisationRoutes from "../../../constants/routes/OrganisationRoutes";
import isInOrg from "../../../constants/isInOrg";
import isOrgAdmin from "../../../constants/isOrgAdmin";
import Loader from "../../widgets/loader/Loader";
import isSysAdmin from "../../../constants/isSysAdmin";
import renderRoutes from "../../../constants/renderRoutes";
import filterRoutes from "../../../constants/filterRoutes";
import Redirect from "react-router/es/Redirect";

class Organisation extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: !props.organisation,
        };
    }

    componentDidMount = () => {
        this.getOrganisation();
    };

    getOrganisation = async () => {
        if (!this.props.orgId) return;
        await this.props.dispatch(getOrganisation(this.props.orgId));
        this.setState({
            loading: false,
        });
    };

    matchPath = (path) => {
        return !!(
            matchPath(this.props.location.pathname, {
                path: path,
                exact: true,
                strict: false,
            }) || {}
        ).isExact;
    };

    renderNavItem = (route) => {
        return (
            <MenuItem key={route.path} onClick={this.onNavItemPress(route)} selected={this.matchPath(route.path)}>
                {route.name}
            </MenuItem>
        );
    };

    onNavItemPress = (route) => {
        const path = route.path.replace(":orgId", this.props.orgId);

        return () => {
            this.props.history.push(path);
        };
    };

    render() {
        if (!this.props.isInOrg) {
            return <Redirect to={"/"} />;
        }

        return (
            <div className={styles.root}>
                <div className={styles.header}>
                    <div className={styles.left}>
                        <h1>{this.props.organisation.name}</h1>
                    </div>
                </div>

                <Paper className={styles.nav_buttons}>
                    {filterRoutes(OrganisationRoutes.filter((r) => !r.isHidden), this.props.user, this.props.orgId).map(
                        this.renderNavItem,
                    )}
                </Paper>

                <Suspense fallback={<Loader />}>
                    <Switch location={this.props.location}>
                        {renderRoutes(OrganisationRoutes, this.props.user, this.props.orgId)}
                    </Switch>
                </Suspense>
            </div>
        );
    }
}

export default Organisation = connect((state, props) => {
    const orgId = Number(props.match.params.orgId);
    const organisation = state.organisations[orgId];
    const allCollections = state.collections.collections;

    const collections = Object.keys(state.collections.organisations[orgId] || {}).map(
        (collectionId) => allCollections[collectionId],
    );
    const user = state.user;

    return {
        isSysAdmin: isSysAdmin(user),
        isAdmin: isAdmin(user),
        isOrgAdmin: isOrgAdmin(user),
        isInOrg: isInOrg(orgId, user),
        orgId: orgId,
        organisation: organisation || {},
        collections: collections,
    };
})(Organisation);
