/**

 ▬▬ι═══════ﺤ            -═══════ι▬▬
 Created by Chris on 24/08/18.
 ▬▬ι═══════ﺤ            -═══════ι▬▬

 **/

import React from "react";
import MatButton from "@material-ui/core/Button";
import PropTypes from "prop-types";
import styles from "./Button.module.scss";
import classnames from "classnames";
import omit from "lodash/omit";
import Loader from "../loader/Loader";

export default function Button(props) {
    const className = classnames(
        styles.button,
        {
            [styles.red]: props.red,
            [styles.yellow]: props.yellow,
            [styles.plain]: props.plain,
            [styles.darkGrey]: props.darkGrey,
            [styles.border]: props.border,
            [styles.green]: props.green,
            [styles.blue]: props.blue,
            [styles.pink]: props.pink,
            [styles.orange]: props.orange,
            [styles.peach]: props.peach,
        },
        props.className,
        props.classes,
    );

    const newProps = omit(props, [...Object.keys(Button.defaultProps), "wrap", "dark"]);

    return (
        <MatButton
            {...newProps}
            className={className}
            onClick={props.onPress}
            disabled={props.loading || props.disabled}>
            {props.loading ? <Loader color={"#fff"} /> : props.children}
        </MatButton>
    );
}

Button.propTypes = {
    className: PropTypes.string,
    classes: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),

    onPress: PropTypes.func,
    active: PropTypes.bool,
    disabled: PropTypes.bool,
    loading: PropTypes.bool,

    red: PropTypes.bool,
    yellow: PropTypes.bool,
    plain: PropTypes.bool,
    darkGrey: PropTypes.bool,
    border: PropTypes.bool,
    green: PropTypes.bool,
    blue: PropTypes.bool,
    pink: PropTypes.bool,
    orange: PropTypes.bool,
    peach: PropTypes.bool,
};

Button.defaultProps = {
    className: null,
    classes: [],

    onPress: null,
    active: false,
    disabled: false,
    loading: false,

    red: false,
    yellow: false,
    plain: false,
    darkGrey: false,
    border: false,
    green: false,
    blue: false,
    pink: false,
    orange: false,
    peach: false,
};
