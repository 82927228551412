/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 22/11/18.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

import createAction from "./helpers/createAction";
import wrapper from "./helpers/wrapper";
import * as DataPacksAPI from "./api/dataPacks";

export const SET_DATA_PACKS = "SET_DATA_PACKS";
export const SET_DATA_PACK = "SET_DATA_PACK";
export const REMOVE_DATA_PACK = "REMOVE_DATA_PACK";

export const setDataPacks = createAction(SET_DATA_PACKS, "dataPacks");
export const setDataPack = createAction(SET_DATA_PACK, "dataPack");
export const removeDataPack = createAction(REMOVE_DATA_PACK, "dataPackId", "orgId");

export const getDataPacksForOrganisation = wrapper(DataPacksAPI.getDataPacksForOrganisationAPI, (dispatch, items) => {
    dispatch(setDataPacks(items));
});

export const createOrUpdateDataPack = wrapper(DataPacksAPI.createOrUpdateDataPackAPI, (dispatch, dp) => {
    dispatch(setDataPack(dp));
});

export const deleteDataPack = wrapper(DataPacksAPI.deleteDataPackAPI, (dispatch, r, dpId, orgId) => {
    dispatch(removeDataPack(dpId, orgId));
});
