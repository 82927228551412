/**

 ▬▬ι═══════ﺤ            -═══════ι▬▬
 Created by Chris on 25/08/18.
 ▬▬ι═══════ﺤ            -═══════ι▬▬

 **/

import * as Type from "../actions/collections";
import uniq from "lodash/uniq";

const initialState = {
    collections: {},
    organisations: {},
};

export default function collections(state = initialState, action) {
    switch (action.type) {
        case Type.SET_COLLECTIONS: {
            const collections = {
                ...state.collections,
            };

            const organisations = {
                ...state.organisations,
            };

            (action.collections || []).map((collection) => {
                const collectionId = collection.id;
                collections[collectionId] = collection;

                organisations[collection.organisation] = {
                    ...(organisations[collection.organisation] || {}),
                    [collectionId]: collectionId,
                };
                return null;
            });

            return {
                ...state,
                collections: collections,
                organisations: organisations,
            };
        }

        case Type.SET_COLLECTION: {
            const collection = action.collection || {};

            return {
                ...state,
                collections: {
                    ...state.collections,
                    [collection.id]: collection,
                },
                organisations: {
                    ...state.organisations,
                    [collection.organisation]: {
                        ...(state.organisations[collection.organisation] || {}),
                        [collection.id]: collection,
                    },
                },
            };
        }

        case Type.REMOVE_COLLECTION_LOCATION: {
            return {
                ...state,
            };
        }

        default: {
            return state;
        }
    }
}
