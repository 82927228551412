import { store } from "../App";
import isAdmin from "./isAdmin";

/**

 ▬▬ι═══════ﺤ            -═══════ι▬▬
 Created by Chris on 17/09/18.
 ▬▬ι═══════ﺤ            -═══════ι▬▬

 **/

export default function isInOrg(orgId, user) {
    if (!user) {
        user = store.getState().user;
    }

    return isAdmin(user) || (user || { userRoles: [] }).userRoles.some((role) => role.organisation === orgId);
}
