import React, { Component } from "react";
import { connect } from "react-redux";
import styles from "./DataPackInstances.module.scss";
import { getDataPackInstances } from "../../../redux/actions/dataPackInstances";
import Table from "../../widgets/table/Table";
import { Link } from "react-router-dom";
import moment from "moment";

class DataPackInstances extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            page: {},
        };
    }

    componentDidMount() {
        this.getDataPackInstances();
    }

    getColumns = () => {
        return [
            {
                name: "ID",
                options: {
                    display: false,
                },
            },
            {
                name: "Access Point",
                options: {
                    customBodyRender: this.renderAccessPointCell,
                },
            },
            {
                name: "Journey",
                options: {
                    display: false,
                    customBodyRender: this.renderJourneyCell,
                },
            },
            "Data Pack",
            {
                name: "User",
                options: {
                    display: false,
                    customBodyRender: this.renderUserCell,
                },
            },
            {
                name: "Created",
                options: {
                    sortDirection: "desc",
                },
            },
            "Expiry",
            "Used MB",
            "Status",
        ];
    };

    renderAccessPointCell = (apId) => {
        return <Link to={`/dashboard/access-points/${apId}`}>{apId}</Link>;
    };

    renderJourneyCell = (journeyId) => {
        return <Link to={`/dashboard/wifi-journeys/${journeyId}`}>{journeyId}</Link>;
    };

    renderUserCell = (dataPackInstance) => {
        const user = dataPackInstance.user || {};
        const email = dataPackInstance.email;
        return user.userId ? <Link to={`/dashboard/users/${user.userId}`}>{email}</Link> : <span>{email}</span>;
    };

    renderTableData = () => {
        const page = this.state.page;
        const items = page.items || [];
        const dataPackInstanceIds = items.map((item) => item.id);
        const dataPackInstances = dataPackInstanceIds.map((id) => this.props.store[id]);

        return dataPackInstances.map((dataPackInstance) => {
            return [
                dataPackInstance.id,
                dataPackInstance.accessPoint,
                dataPackInstance.journey,
                dataPackInstance.dataPack.name,
                dataPackInstance,
                moment(dataPackInstance.createdAt).format(),
                moment(dataPackInstance.expiry).format(),
                Math.round(dataPackInstance.usedBytes / (1024 * 1024)),
                dataPackInstance.status,
            ];
        });
    };

    getDataPackInstances = async () => {
        this.setState({
            loading: true,
        });
        try {
            const params = {
                limit: 999,
            };
            const apId = Number(this.props.match.params.apId);
            if (apId) {
                params.accessPoint = apId;
            }
            const wjId = Number(this.props.match.params.wjId);
            if (wjId) {
                params.journey = wjId;
            }
            const page = await this.props.dispatch(getDataPackInstances(params));
            this.setState({
                loading: false,
                page: page,
            });
        } catch (err) {
            this.setState({
                loading: false,
                error: err.message,
            });
        }
    };

    render() {
        const options = {
            filter: false,
            responsive: "stacked",
        };
        return (
            <div className={styles.root}>
                <Table
                    options={options}
                    loading={this.state.loading}
                    title={"Data Packs"}
                    data={this.renderTableData()}
                    columns={this.getColumns()}
                />
            </div>
        );
    }
}

export default DataPackInstances = connect((state, props) => {
    return {
        store: state.dataPackInstances,
    };
})(DataPackInstances);
