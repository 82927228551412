/**

 ▬▬ι═══════ﺤ            -═══════ι▬▬
 Created by Chris on 24/08/18.
 ▬▬ι═══════ﺤ            -═══════ι▬▬

 **/

import React from "react";
import Error from "./Error";
import { Field } from "redux-form";

const ErrorField = (props) => <Field name={"error"} {...props} component={Error} />;

ErrorField.propTypes = Error.propTypes;
ErrorField.defaultProps = Error.defaultProps;

export default ErrorField;
