import * as JasperAPI from "./api/jasper";

export const SET_JASPER_DEVICE = "SET_JASPER_DEVICE";
export const SET_COMMS_PLANS = "SET_COMMS_PLANS";

export const getCommsPlans = () => {
    return async (dispatch) => {
        const result = await JasperAPI.getCommsPlans();
        dispatch({
            type: SET_COMMS_PLANS,
            data: result,
        });
        return result;
    };
};

export const getJasperDevice = (iccid) => {
    return async (dispatch) => {
        const result = await JasperAPI.getJasperDevice(iccid);
        dispatch({
            type: SET_JASPER_DEVICE,
            data: result,
        });
        return result;
    };
};

export const updateJasperDevice = (iccid, payload) => {
    return async (dispatch) => {
        await JasperAPI.updateJasperDevice(iccid, payload);
        const result = await JasperAPI.getJasperDevice(iccid);
        dispatch({
            type: SET_JASPER_DEVICE,
            data: result,
        });
        return result;
    };
};
