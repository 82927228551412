import LocationBookings from "../../components/routes/location/routes/bookings/LocationBookings";
import LocationDetails from "../../components/routes/location/routes/details/LocationDetails";
import LocationSites from "../../components/routes/location/routes/sites/LocationSites";

const LocationRoutes = [
    {
        name: "Details",
        path: "/dashboard/locations/:locationId",
        component: LocationDetails,
        isHidden: false,
        exact: true,
    },
    {
        name: "Sites",
        path: "/dashboard/locations/:locationId/sites",
        component: LocationSites,
        isHidden: false,
        exact: true,
    },
    {
        name: "Bookings",
        path: "/dashboard/locations/:locationId/bookings",
        component: LocationBookings,
        isHidden: false,
        exact: true,
    },
];

export default LocationRoutes;
