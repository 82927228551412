/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 22/11/18.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

import getToken from "./helpers/getAuthToken";
import get from "./api/util/get";
import put from "./api/util/put";

export const SET_ACCESS_POINTS = "SET_ACCESS_POINTS";
export const SET_ACCESS_POINT = "SET_ACCESS_POINT";

export const getAccessPoints = (params) => {
    return async (dispatch) => {
        const token = await getToken();
        const result = await get("v1/access-points", token, params);
        dispatch({
            type: SET_ACCESS_POINTS,
            items: result.items,
        });
        return result;
    };
};

export const searchAccessPoints = (params) => {
    return async (dispatch) => {
        const token = await getToken();
        const result = await get("v1/access-point-search", token, params);
        dispatch({
            type: SET_ACCESS_POINTS,
            items: result.items,
        });
        return result;
    };
};

export const getAccessPoint = (id) => {
    return async (dispatch) => {
        const token = await getToken();
        const accessPoint = await get(`v1/access-points/${id}`, token);
        dispatch({
            type: SET_ACCESS_POINT,
            accessPoint: accessPoint,
        });
        return accessPoint;
    };
};

export const allowAccessPoint = (id) => {
    return async (dispatch) => {
        const token = await getToken();
        const accessPoint = await put(`v1/access-points/allow/${id}`, token);
        dispatch({
            type: SET_ACCESS_POINT,
            accessPoint: accessPoint,
        });
        return accessPoint;
    };
};

export const blockAccessPoint = (id) => {
    return async (dispatch) => {
        const token = await getToken();
        const accessPoint = await put(`v1/access-points/block/${id}`, token);
        dispatch({
            type: SET_ACCESS_POINT,
            accessPoint: accessPoint,
        });
        return accessPoint;
    };
};

export const updateAccessPoints = (payload) => {
    return async (dispatch) => {
        const token = await getToken();
        const accessPoints = await put("v1/access-points/batch", token, payload);
        dispatch({
            type: SET_ACCESS_POINTS,
            items: accessPoints,
        });
        return accessPoints;
    };
};
