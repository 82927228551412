/**

 ▬▬ι═══════ﺤ            -═══════ι▬▬
 Created by Chris on 25/08/18.
 ▬▬ι═══════ﺤ            -═══════ι▬▬

 **/

import React, { Component } from "react";
import { connect } from "react-redux";
import styles from "./Organisations.module.scss";
import { getOrganisations } from "../../../redux/actions/organisations";
import Table from "../../widgets/table/Table";
import AddIcon from "@material-ui/icons/Add";
import values from "lodash/values";
import LinkButton from "../../widgets/button/LinkButton";
import { ORGANISATION_PATH } from "../../../constants/routes/Routes";

const INITIAL_STATE = {
    loading: false,
    cursor: null,
    selectedOrg: null,
};

class Organisations extends Component {
    constructor(props) {
        super(props);

        this.state = INITIAL_STATE;

        this.columns = [
            "Id",
            "Name",
            {
                name: "",
                options: {
                    customHeadRender: this.renderAddNewOrg,
                    customBodyRender: this.renderEditOrgButtons,
                },
            },
        ];
    }

    componentDidMount() {
        this.getOrganisations();
    }

    getOrganisations = async () => {
        this.setState({
            loading: true
        });

        const params = {
            cursor: this.state.cursor,
        };

        const result = await this.props.dispatch(getOrganisations(params));
        this.setState({
            loading: false,
            cursor: result.cursor,
        });
    };

    renderAddNewOrg = () => {
        return (
            <th key={"renderAddNewOrg"} className={styles.table_header_item}>
                <div className={styles.inner}>
                    <LinkButton to={ORGANISATION_PATH.replace(":orgId", "new")}>
                        <AddIcon />
                        Add
                    </LinkButton>
                </div>
            </th>
        );
    };

    renderEditOrgButtons = (orgId) => {
        return (
            <div className={styles.edit_buttons}>
                <LinkButton to={`/dashboard/organisations/${orgId}`}>View</LinkButton>
            </div>
        );
    };

    renderTableData = () => {
        return values(this.props.organisations).map((org) => {
            return [org.id, org.name, org.id];
        });
    };

    render() {
        return (
            <div className={styles.organisations_container}>
                <Table
                    title={"Organisations"}
                    data={this.renderTableData()}
                    columns={this.columns}
                    loading={this.state.loading}
                />
            </div>
        );
    }
}

export default Organisations = connect((state) => {
    return {
        organisations: state.organisations,
    };
})(Organisations);
