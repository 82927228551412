/**

 ▬▬ι═══════ﺤ            -═══════ι▬▬
 Created by Chris on 04/12/18.
 ▬▬ι═══════ﺤ            -═══════ι▬▬

 **/

import React from "react";
import Field from "redux-form/es/Field";
import TextInput from "../textInput/TextInput";
import CheckBox from "./CheckBox";

const CheckBoxField = (props) => <Field {...props} component={CheckBox} />;

CheckBoxField.propTypes = TextInput.propTypes;
CheckBoxField.defaultProps = TextInput.defaultProps;

export default CheckBoxField;
