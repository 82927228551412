/**

 ▬▬ι═══════ﺤ            -═══════ι▬▬
 Created by Chris on 16/01/18.
 ▬▬ι═══════ﺤ            -═══════ι▬▬

 **/

const FORMATTERS = {
    //Default (e.g. NZD,AUD,USD): dollars to cents
    DEFAULT: (a) => Number(a) * 100,
    //China: no conversion
    RMB: (a) => Number(a),
};

export default function toLowestCurrencyFormatter(amount, code) {
    return (FORMATTERS[code] || FORMATTERS.DEFAULT)(amount);
}
