/**

 ▬▬ι═══════ﺤ            -═══════ι▬▬
 Created by Chris on 6/09/18.
 ▬▬ι═══════ﺤ            -═══════ι▬▬

 **/

import React from "react";
import Field from "redux-form/es/Field";
import DatePicker from "./DatePicker";

const DatePickerField = (props) => <Field {...props} component={DatePicker} />;

DatePickerField.propTypes = DatePicker.propTypes;
DatePickerField.defaultProps = DatePicker.defaultProps;

export default DatePickerField;
