import React, { Component } from "react";
import { connect } from "react-redux";
import styles from "./LocationDetails.module.scss";
import isSysAdmin from "../../../../../constants/isSysAdmin";
import isAdmin from "../../../../../constants/isAdmin";
import { reduxForm } from "redux-form";
import Button from "../../../../widgets/button/Button";
import TextInputField from "../../../../widgets/textInput/TextInputField";
import { getLocation, putLocation } from "../../../../../redux/actions/locations";
import SelectField from "../../../../widgets/select/SelectField";
import CheckBoxField from "../../../../widgets/checkBox/CheckBoxField";
import GeoLocationMapSelectField from "../../../../widgets/geoLocation/GeoLocationMapSelectField";
import ImageArraySelectorField from "../../../../widgets/image/ImageArraySelectorField";
import CheckBoxMultiSelectField from "../../../../widgets/select/CheckBoxMultiSelectField";
import { getTags } from "../../../../../redux/actions/tags";

class LocationDetails extends Component {
    state = {};

    componentDidMount = () => {
        this.getLocation();
        if (!this.props.tags.length) {
            this.props.dispatch(getTags());
        }
    };
    getLocation = async () => {
        if (!this.props.locationId) {
            return;
        }
        await this.props.dispatch(getLocation(this.props.locationId));
        this.setState({
            loading: false,
        });
    };

    putLocation = (location) => {
        return this.props.dispatch(putLocation(location));
    };

    onSubmit = async (values) => {
        try {
            const location = await this.putLocation(values);
            this.setState(
                {
                    loading: false,
                    locationId: location.id,
                },
                this.onClose,
            );
        } catch (err) {
            this.setState({
                loading: false,
                error: err.message,
            });
        }
    };

    render() {
        const { handleSubmit, pristine, submitting } = this.props;

        const statusOptions = [{ value: "active", label: "On" }, { value: "inactive", label: "Off" }];

        return (
            <form onSubmit={handleSubmit(this.onSubmit)}>
                <div className={styles.details}>
                    <div>
                        <SelectField
                            className={styles.text_input}
                            name={"status"}
                            label={"Status"}
                            options={statusOptions}
                            native
                        />
                    </div>
                    <div>
                        <CheckBoxField
                            className={styles.text_input}
                            name={"isPublic"}
                            label={"Publicly visible"}
                        />
                    </div>
                    <div>
                        <TextInputField className={styles.text_input} name="name" label="Name"/>
                    </div>
                    <div>
                        <TextInputField className={styles.text_input} name="address" label="Address"/>
                    </div>
                    <div>
                        <TextInputField
                            className={styles.text_input}
                            name="description"
                            label="Description"
                            multiline
                        />
                    </div>
                    <div>
                        <TextInputField className={styles.text_input} name="website" label="Website"/>
                    </div>
                    <div>
                        <GeoLocationMapSelectField className={styles.text_input} names={["latitude", "longitude"]}/>
                    </div>
                    <div>
                        <ImageArraySelectorField className={styles.text_input} name="images"/>
                    </div>
                    {this.props.isAdmin ? (
                        <CheckBoxMultiSelectField
                            label="Admin Tags"
                            className={styles.text_input}
                            name="tags"
                            options={this.props.tags}
                        />
                    ) : null}

                    <div className={styles.submit_button}>
                        <Button type="submit" disabled={pristine || submitting} loading={submitting}>
                            Save
                        </Button>
                    </div>
                </div>
            </form>
        );
    }
}

LocationDetails = reduxForm({
    form: "updateLocation",
    enableReinitialize: true,
})(LocationDetails);

export default LocationDetails = connect((state, props) => {
    const locationId = Number(props.match.params.locationId);
    const location = state.locations[locationId] || {};
    const user = state.user;
    const tags = (state.tags.items || [])
        // CAMP-731 HJ: Removed this filter to allow admins to modify all tags
        // .filter((tag) => tag.group !== "site_feature")
        .map((tag) => ({ ...tag, value: tag.id }));

    return {
        isSysAdmin: isSysAdmin(user),
        isAdmin: isAdmin(user),
        locationId: locationId,
        location: location,
        initialValues: { ...location },
        tags: tags,
    };
})(LocationDetails);
