import React from "react";
import Fields from "redux-form/es/Fields";
import GeoLocationMapSelect from "./GeoLocationMapSelect";

const GeoLocationMapSelectField = (props) => <Fields {...props} component={GeoLocationMapSelect} />;

GeoLocationMapSelectField.propTypes = GeoLocationMapSelect.propTypes;
GeoLocationMapSelectField.defaultProps = GeoLocationMapSelect.defaultProps;

export default GeoLocationMapSelectField;
