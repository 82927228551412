import getToken from "./helpers/getAuthToken";
import get from "./api/util/get";

export const SET_REGIONS = "SET_REGIONS";

export const getRegions = (params) => {
    return async (dispatch) => {
        const token = await getToken();
        const result = await get("v1/regions", token, params);
        dispatch({
            type: SET_REGIONS,
            regions: result,
        });
        return result;
    };
};
