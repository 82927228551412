import * as Type from "../../actions/jasper";

const initialState = {};

export default function devices(state = initialState, action) {
    switch (action.type) {
        case Type.SET_JASPER_DEVICE: {
            return {
                ...state,
                [action.data.iccid]: action.data,
            };
        }
        default:
            return state;
    }
}
