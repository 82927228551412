import getToken from "./helpers/getAuthToken";
import post from "./api/util/post";

export const SET_IMAGES = "SET_IMAGES";

export const createImage = (files) => {
    return async (dispatch) => {
        const token = await getToken();
        const formData = new FormData();
        formData.isformdata = true;
        for (let i = 0; i < files.length; i++) {
            formData.append("files", files[i]);
        }

        const result = await post("/v1/images", token, formData);
        dispatch({
            type: SET_IMAGES,
            items: result,
        });
        return result;
    };
};
