/**
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 * Created by Chris on 19/04/18.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 **/

import fetchJSON from "../redux/actions/api/util/fetchJSON";

window.fetchJSON = fetchJSON;

/* eslint-disable no-extend-native */
String.prototype.toProperCase = function() {
    return this.replace(/\w\S*/g, function(txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
};

// eslint-disable-next-line
String.prototype.toProperCase = function() {
    return this.toLowerCase().replace(/(^|[\s\xA0])[^\s\xA0]/g, function(s) {
        return s.toUpperCase();
    });
};
