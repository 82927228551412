import TableFooter from "@material-ui/core/TableFooter";
import TableRow from "@material-ui/core/TableRow";
import TablePagination from "@material-ui/core/TablePagination";
import React from "react";

const getDisplayedRowsText = ({ from, to, count, end }) => {
    return `${from}-${to} of ${end ? count : "many"}`;
};

const DataTableCursorFooter = (end) => (count, page, rowsPerPage, changeRowsPerPage, changePage) => (
    <TableFooter>
        <TableRow>
            <TablePagination
                labelDisplayedRows={(args) => getDisplayedRowsText({ ...args, end })}
                count={end ? count : count + 999}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={(_, page) => changePage(page)}
                onChangeRowsPerPage={(event) => changeRowsPerPage(event.target.value)}
                rowsPerPageOptions={[10, 30, 100]}
            />
        </TableRow>
    </TableFooter>
);

export default DataTableCursorFooter;
