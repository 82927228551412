import React, { Component } from "react";

import DragAndDropGallery from "../../common/DndGallery";
import { createImage } from "../../../redux/actions/images";
import { FormHelperText } from "@material-ui/core";
import classnames from "classnames";
import { FormControl } from "@material-ui/core";
import { connect } from "react-redux";

class ImageArraySelector extends Component {
    onRemoveImage = (imageId) => {
        const array = this.props.input.value;
        const removed = [];
        array.map((image) => {
            if (image.id !== imageId) {
                removed.push(image);
            }
            return null;
        });
        this.props.input.onChange(removed);
    };

    onChangeImageOrder = (newData) => {
        this.props.input.onChange(newData);
    };

    onDrop = async (files) => {
        const array = this.props.input.value;
        // this.props.dispatch(showAlert("warning", "Image uploading...", "Your image is uploading..."));

        const images = await this.props.dispatch(createImage(files));
        // this.props.dispatch(showAlert("success", "Image uploaded", "Select update to save your changes"));
        this.props.input.onChange((array || []).concat(images));
    };

    render() {
        let error = this.props.error;

        if (this.props.meta) {
            error = this.props.meta.touched && this.props.meta.error;
        }

        return (
            <FormControl className={classnames(this.props.className, this.props.classes)}>
                <h4>Images</h4>
                <br />
                <DragAndDropGallery
                    onChangeImageOrder={this.onChangeImageOrder}
                    onDrop={this.onDrop}
                    onRemoveImage={this.onRemoveImage}
                    className="photos"
                    images={this.props.input.value}
                />
                <FormHelperText hidden={!error} error>
                    {error}
                </FormHelperText>
            </FormControl>
        );
    }
}

export default ImageArraySelector = connect(() => ({}))(ImageArraySelector);
