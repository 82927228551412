import React, { Component } from "react";
import { connect } from "react-redux";
import styles from "./AccessPoint.module.scss";
import { getAccessPoint, allowAccessPoint, blockAccessPoint } from "../../../redux/actions/accessPoints";
import { getOrganisation } from "../../../redux/actions/organisations";
import { InputLabel, Divider, TextField } from "@material-ui/core";
import Button from "../../widgets/button/Button";
import Chip from "../../widgets/chip/Chip";
import StartWifiJourneyDialog from "../../widgets/dialog/startWifiJourneyDialog/StartWifiJourneyDialog";
import Loader from "../../widgets/loader/Loader";
import { Link } from "react-router-dom";
import EditAccessPointsDialog from "../../widgets/dialog/editAccessPointsDialog/EditAccessPointsDialog";
import {getJasperDevice} from "../../../redux/actions/jasper";

class AccessPointHome extends Component {
    constructor(props) {
        super(props);

        this.state = {};
    }

    componentDidMount = () => {
        this.getAccessPoint();
        this.getOrganisation();
    };

    componentDidUpdate = (prevProps) => {
        const prevOrgId = (prevProps.accessPoint || {}).organisation;
        const newOrgId = (this.props.accessPoint || {}).organisation;
        if (prevOrgId !== newOrgId) {
            this.getOrganisation();
        }
        if(this.props.accessPoint !== prevProps.accessPoint) {
            this.getJasperDetails();
        }
    };

    getJasperDetails = async () => {
        if(!this.props.accessPoint) {
            return;
        }
        return this.props.dispatch(getJasperDevice(this.props.accessPoint.iccid));
    }

    getAccessPoint = async () => {
        if (!this.props.apId) {
            return;
        }
        this.setState({
            apLoading: true,
        });
        try {
            await this.props.dispatch(getAccessPoint(this.props.apId));
            this.setState({
                apLoading: false,
                journeyStatusLoading: false,
            });
        } catch (err) {
            this.setState({
                apLoading: false,
                journeyStatusLoading: false,
                error: err.message,
            });
        }
    };

    getOrganisation = async () => {
        if (!this.props.accessPoint) {
            return;
        }
        this.setState({
            orgLoading: true,
        });
        try {
            await this.props.dispatch(getOrganisation(this.props.accessPoint.organisation));
            this.setState({
                orgLoading: false,
                journeyStatusLoading: false,
            });
        } catch (err) {
            this.setState({
                orgLoading: false,
                journeyStatusLoading: false,
                error: err.message,
            });
        }
    };

    onStartJourneyPress = () => {
        this.setState({
            showStartJourneyDialog: true,
            error: null,
        });
    };

    onStartJourneyDialogClose = () => {
        this.setState({
            journeyStatusLoading: false,
            showStartJourneyDialog: false,
            error: null,
        });
    };

    onChangeFirewallStatusPress = async () => {
        const allowed = this.props.accessPoint.status === "allowed";
        this.setState({
            firewallStatusLoading: true,
            error: null,
        });

        try {
            if (allowed) {
                await this.props.dispatch(blockAccessPoint(this.props.accessPoint.id));
            } else {
                await this.props.dispatch(allowAccessPoint(this.props.accessPoint.id));
            }

            this.setState({
                firewallStatusLoading: false,
            });
        } catch (err) {
            this.setState({
                firewallStatusLoading: false,
                error: {
                    firewallStatus: err.message,
                },
            });
        }
    };

    onEditPress = () => {
        this.setState({
            editDialogShown: true,
        });
    };

    onEditDialogClose = () => {
        this.setState({
            editDialogShown: false,
        });
    };

    render() {
        const ap = this.props.accessPoint || {};
        const organisation = this.props.organisation || {};
        const region = ap.region || {};
        const label = ap.label || {};
        const activeWifiJourney = ap.activeWifiJourney || {};
        const hasActiveJourney = !!ap.activeWifiJourney;
        const firewallAllowed = ap.status === "allowed";

        const loading = this.state.apLoading || this.state.orgLoading;

        const startViewJourneyButton = hasActiveJourney ? (
            <Link className={styles.status_button} to={`/dashboard/wifi-journeys/${activeWifiJourney.id}`}>
                <Button>View</Button>
            </Link>
        ) : (
            <Button className={styles.status_button} onPress={this.onStartJourneyPress}>
                Start
            </Button>
        );

        return loading ? (
            <Loader />
        ) : (
            <div>
                <TextField
                    label="ID"
                    value={ap.id || ""}
                    margin="normal"
                    className={styles.text_input}
                    InputProps={{
                        readOnly: true,
                    }}
                />
                <TextField
                    label="Label"
                    value={label.deviceId || ""}
                    margin="normal"
                    className={styles.text_input}
                    InputProps={{
                        readOnly: true,
                    }}
                />
                <TextField
                    label="Deep Link"
                    value={label.deepLink || ""}
                    margin="normal"
                    className={styles.text_input}
                    InputProps={{
                        readOnly: true,
                    }}
                />
                <TextField
                    label="ICCID"
                    value={ap.iccid || ""}
                    margin="normal"
                    className={styles.text_input}
                    InputProps={{
                        readOnly: true,
                    }}
                />
                <TextField
                    label="IP Address"
                    value={ap.ipAddress || ""}
                    margin="normal"
                    className={styles.text_input}
                    InputProps={{
                        readOnly: true,
                    }}
                />
                <TextField
                    label="BSSID"
                    value={ap.bssid || ""}
                    margin="normal"
                    className={styles.text_input}
                    InputProps={{
                        readOnly: true,
                    }}
                />

                <Divider className={styles.divider}/>

                <TextField
                    label="Organisation"
                    value={organisation.name || ""}
                    margin="normal"
                    className={styles.text_input}
                    InputProps={{
                        readOnly: true,
                    }}
                />
                <TextField
                    label="Region"
                    value={region.name || ""}
                    margin="normal"
                    className={styles.text_input}
                    InputProps={{
                        readOnly: true,
                    }}
                />
                <TextField
                    label="Time Zone"
                    value={ap.timeZone || ""}
                    margin="normal"
                    className={styles.text_input}
                    InputProps={{
                        readOnly: true,
                    }}
                />
                <TextField
                    label="Tags"
                    value={ap.tags || ""}
                    margin="normal"
                    className={styles.text_input}
                    InputProps={{
                        readOnly: true,
                    }}
                />
                <TextField
                    label="Communication Plan"
                    value={this.props.commsPlan || ""}
                    margin="normal"
                    className={styles.text_input}
                    InputProps={{
                        readOnly: true,
                    }}
                />
                <p />
                <Button className={styles.toolbar_button} onPress={this.onEditPress}>
                    Edit
                </Button>

                <Divider className={styles.divider}/>

                <div className={styles.status_field_container}>
                    <InputLabel>Journey Status</InputLabel>
                    <div className={styles.status_container}>
                        <Chip className={styles.status} green={hasActiveJourney} red={!hasActiveJourney}>
                            {hasActiveJourney ? "ACTIVE" : "INACTIVE"}
                        </Chip>
                        {startViewJourneyButton}
                    </div>
                </div>

                <div className={styles.status_field_container}>
                    <InputLabel>Firewall Status</InputLabel>
                    <div className={styles.status_container}>
                        <Chip className={styles.status} green={firewallAllowed} red={!firewallAllowed}>
                            {firewallAllowed ? "ALLOWED" : "BLOCKED"}
                        </Chip>
                        {hasActiveJourney ? (
                            <Button
                                loading={!!this.state.firewallStatusLoading}
                                onPress={this.onChangeFirewallStatusPress}>
                                {firewallAllowed ? "Block" : "Allow"}
                            </Button>
                        ) : (
                            ""
                        )}
                        <div className={styles.error}>{(this.state.error || {}).firewallStatus}</div>
                    </div>
                </div>

                <EditAccessPointsDialog
                    shown={this.state.editDialogShown}
                    accessPoints={[ap]}
                    onClose={this.onEditDialogClose}
                />

                <StartWifiJourneyDialog
                    shown={this.state.showStartJourneyDialog}
                    onClose={this.onStartJourneyDialogClose}
                    accessPointId={this.props.apId}
                />
            </div>
        );
    }
}

export default AccessPointHome = connect((state, props) => {
    const apId = Number(props.match.params.apId);
    const accessPoint = state.accessPoints[apId];
    const organisation = state.organisations[(accessPoint || {}).organisation];

    const ap = accessPoint || {};
    const iccid = ap.iccid && ap.iccid.replace('F','');
    const device = state.jasper.devices[iccid] || {};
    const commsPlan = device.communicationPlan;

    return {
        apId,
        accessPoint,
        organisation,
        commsPlan,
    };
})(AccessPointHome);
