import React from "react";
import Field from "redux-form/es/Field";
import ImageArraySelector from "./ImageArraySelector";

const ImageArraySelectorField = (props) => <Field {...props} component={ImageArraySelector} />;

ImageArraySelectorField.propTypes = ImageArraySelector.propTypes;
ImageArraySelectorField.defaultProps = ImageArraySelector.defaultProps;

export default ImageArraySelectorField;
