/**

 ▬▬ι═══════ﺤ            -═══════ι▬▬
 Created by Chris on 21/07/17.
 ▬▬ι═══════ﺤ            -═══════ι▬▬

 **/

import fetchJSON from "./fetchJSON";

export default function del(url, token, data) {
    return fetchJSON("delete", url, token, data);
}
