/**

 ▬▬ι═══════ﺤ            -═══════ι▬▬
 Created by Chris on 25/08/18.
 ▬▬ι═══════ﺤ            -═══════ι▬▬

 **/

import React from "react";

export default function campableLogo(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 300 64.643" {...props}>
            <defs>
                <style>{".campable_logo_a{fill:#004d40;}.campable_logo_b{fill:#5fce17;}"}</style>
            </defs>
            <g transform="translate(0 0.001)">
                <g transform="translate(76.526 11.371)">
                    <g transform="translate(99.466 8.606)">
                        <rect className="campable_logo_a" width="6.066" height="36.392" />
                    </g>
                    <g transform="translate(99.466 8.606)">
                        <path
                            className="campable_logo_a"
                            d="M554.117,67.416A7.581,7.581,0,1,1,546.536,75a7.588,7.588,0,0,1,7.581-7.581m0-6.066A13.647,13.647,0,1,0,567.764,75,13.646,13.646,0,0,0,554.117,61.35Z"
                            transform="translate(-540.47 -61.35)"
                        />
                    </g>
                    <g transform="translate(45.727 8.607)">
                        <rect
                            className="campable_logo_a"
                            width="6.066"
                            height="27.294"
                            transform="translate(6.066 27.294) rotate(180)"
                        />
                    </g>
                    <g transform="translate(54.207 8.607)">
                        <rect
                            className="campable_logo_a"
                            width="6.066"
                            height="27.294"
                            transform="translate(6.066 27.294) rotate(180)"
                        />
                    </g>
                    <g transform="translate(24.5 8.606)">
                        <path
                            className="campable_logo_a"
                            d="M323.9,67.416A7.581,7.581,0,1,1,316.316,75a7.588,7.588,0,0,1,7.581-7.581m0-6.066A13.647,13.647,0,1,0,337.544,75,13.646,13.646,0,0,0,323.9,61.35Z"
                            transform="translate(-310.25 -61.35)"
                        />
                    </g>
                    <g transform="translate(148.631 8.607)">
                        <rect
                            className="campable_logo_a"
                            width="6.066"
                            height="27.294"
                            transform="translate(6.066 27.294) rotate(180)"
                        />
                    </g>
                    <g transform="translate(127.405 8.606)">
                        <path
                            className="campable_logo_a"
                            d="M639.917,67.416A7.581,7.581,0,1,1,632.336,75a7.588,7.588,0,0,1,7.581-7.581m0-6.066A13.647,13.647,0,1,0,653.564,75,13.645,13.645,0,0,0,639.917,61.35Z"
                            transform="translate(-626.27 -61.35)"
                        />
                    </g>
                    <g transform="translate(199.212 19.486)">
                        <path
                            className="campable_logo_a"
                            d="M846.79,94.76v5.6h23.729l-4.7-5.6Z"
                            transform="translate(-846.79 -94.76)"
                        />
                    </g>
                    <g transform="translate(158.359)">
                        <path
                            className="campable_logo_a"
                            d="M721.33,36.392V70.824H727.4V34.92A58.584,58.584,0,0,1,721.33,36.392Z"
                            transform="translate(-721.33 -34.92)"
                        />
                    </g>
                    <g transform="translate(187.877 1.56)">
                        <rect className="campable_logo_a" width="6.066" height="34.344" />
                    </g>
                    <g transform="translate(158.359 8.606)">
                        <path
                            className="campable_logo_a"
                            d="M734.977,67.416A7.581,7.581,0,1,1,727.4,75a7.588,7.588,0,0,1,7.581-7.581m0-6.066A13.647,13.647,0,1,0,748.624,75,13.646,13.646,0,0,0,734.977,61.35Z"
                            transform="translate(-721.33 -61.35)"
                        />
                    </g>
                    <g transform="translate(196.18 8.61)">
                        <path
                            className="campable_logo_a"
                            d="M856.532,80.315a7.574,7.574,0,1,1,1.625-2.478h6.32a13.665,13.665,0,1,0-2.188,5.015Z"
                            transform="translate(-837.48 -61.36)"
                        />
                    </g>
                    <g transform="translate(0 8.606)">
                        <path
                            className="campable_logo_a"
                            d="M248.66,82.578a7.581,7.581,0,1,1,5.405-12.885l5.767-2.524a13.648,13.648,0,1,0-.013,15.672l-5.76-2.533A7.537,7.537,0,0,1,248.66,82.578Z"
                            transform="translate(-235.01 -61.35)"
                        />
                    </g>
                    <g transform="translate(54.135 8.463)">
                        <path
                            className="campable_logo_a"
                            d="M407.786,88.338H401.26V73.388a12.478,12.478,0,0,1,24.956,0V88.142h-6.525V73.388a5.953,5.953,0,0,0-11.905,0Z"
                            transform="translate(-401.26 -60.91)"
                        />
                    </g>
                    <g transform="translate(72.602 8.463)">
                        <path
                            className="campable_logo_a"
                            d="M464.5,88.338H457.97V73.388a12.478,12.478,0,1,1,24.956,0V88.142H476.4V73.388a5.953,5.953,0,0,0-11.905,0Z"
                            transform="translate(-457.97 -60.91)"
                        />
                    </g>
                </g>
                <path
                    className="campable_logo_b"
                    d="M32.426,33.4a6.158,6.158,0,1,0,0,12.315H61.745a32.337,32.337,0,1,0-9.2,11.824H32.426A17.979,17.979,0,0,1,14.448,39.557h0A17.979,17.979,0,0,1,32.426,21.579h9.147V33.4H32.426Zm1.8-15.35-1.98-3.452-1.98,3.452H23.172L32.244,2.24l9.072,15.806Z"
                    transform="translate(0 0)"
                />
            </g>
        </svg>
    );
}
