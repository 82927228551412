import React, { Component } from "react";
import { connect } from "react-redux";
import styles from "./LocationBookings.module.scss";
import isAdmin from "../../../../../constants/isAdmin";
import Table from "../../../../widgets/table/Table";
import values from "lodash/values";
import { getBookings } from "../../../../../redux/actions/bookings";
import { Link } from "react-router-dom";

class LocationBookings extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            selectedBookingId: null,
            deleteLoading: false,
            deleteError: null,
        };

        const columns = [
            "Check in",
            {
                name: "Guest",
                options: {
                    customBodyRender: this.renderGuest,
                },
            },
            "Rego",
            {
                name: "Site",
                options: {
                    customBodyRender: this.renderSite,
                },
            },
            "Price",
        ];

        if (this.props.isAdmin) {
            columns.push({
                name: "",
                options: {
                    customHeadRender: this.renderCreateNewButton,
                    customBodyRender: this.renderRowEditButtons,
                },
            });
        }

        this.columns = columns;
    }

    renderGuest = (user) => {
        return <Link to={`/dashboard/users/${user.id}`}>{user.name}</Link>;
    };

    renderSite = (site) => {
        return <Link to={`/dashboard/locations/${this.props.locationId}/sites/${site.id}`}>{site.name}</Link>;
    };

    componentDidUpdate(prevProps) {
        if (!this.state.loadingBookings && this.props.location.owner) {
            this.setState({ loadingBookings: true }, this.getBookings);
        }
    }

    getBookings = async () => {
        await this.props.dispatch(
            getBookings({
                host: this.props.location.owner,
                location: this.props.locationId,
            }),
        );
        this.setState({
            loading: false,
        });
    };

    onClose = () => {
        this.setState({
            selectedBookingId: null,
            deleteLoading: false,
            deleteError: null,
        });
    };

    renderTableData = () => {
        return values(this.props.bookings).map((booking) => {
            const columns = [
                booking.date,
                { id: booking.guest, name: booking.guestFirstName + " " + booking.guestLastName },
                booking.guestVehicle.registration || "Pending",
                { id: booking.site, name: booking.siteName },
                "$" + booking.price / 100,
            ];
            return columns;
        });
    };

    render() {
        return (
            <div className={styles.location_bookings}>
                <Table
                    title={"Bookings"}
                    data={this.renderTableData()}
                    columns={this.columns}
                    loading={this.state.loading}
                />
            </div>
        );
    }
}

export default LocationBookings = connect((state, props) => {
    const locationId = Number(props.match.params.locationId);
    const location = state.locations[locationId] || {};
    const bookings = values(state.bookings.bookings).filter((x) => x.location === locationId);

    return {
        isAdmin: isAdmin(state.user),
        locationId: locationId,
        location: location,
        bookings: bookings,
    };
})(LocationBookings);
