/**

 ▬▬ι═══════ﺤ            -═══════ι▬▬
 Created by Chris on 2/09/18.
 ▬▬ι═══════ﺤ            -═══════ι▬▬

 **/

import createAction from "./helpers/createAction";
import wrapper from "./helpers/wrapper";
import * as CampaignsAPI from "./api/campaigns";

export const SET_CAMPAIGNS = "SET_CAMPAIGNS";
export const SET_CAMPAIGN = "SET_CAMPAIGN";

export const setCampaigns = createAction(SET_CAMPAIGNS, "items");
export const setCampaign = createAction(SET_CAMPAIGN, "campaign");

export const getCampaigns = wrapper(CampaignsAPI.getCampaignsAPI, (dispatch, r) => {
    dispatch(setCampaigns(r.items));
});

export const createCampaign = wrapper(CampaignsAPI.createCampaignAPI, (dispatch, r) => {
    dispatch(setCampaign(r));
});

export const updateCampaign = wrapper(CampaignsAPI.updateCampaignAPI, (dispatch, r) => {
    dispatch(setCampaign(r));
});
