/**

 ▬▬ι═══════ﺤ            -═══════ι▬▬
 Created by Chris on 19/06/18.
 ▬▬ι═══════ﺤ            -═══════ι▬▬

 **/

import React from "react";
import Field from "redux-form/es/Field";
import TextInput from "./TextInput";

const TextInputField = (props) => <Field {...props} component={TextInput} />;

TextInputField.propTypes = TextInput.propTypes;
TextInputField.defaultProps = TextInput.defaultProps;

export default TextInputField;
