/**

 ▬▬ι═══════ﺤ            -═══════ι▬▬
 Created by Chris on 22/08/18.
 ▬▬ι═══════ﺤ            -═══════ι▬▬

 **/

import storage from "redux-persist/lib/storage";
import { persistCombineReducers } from "redux-persist";
import formReducer from "redux-form/es/reducer";

import user from "./user";
import locations from "./locations";
import organisations from "./organisations";
import collections from "./collections";
import campaigns from "./campaigns";
import users from "./users";
import dataPacks from "./dataPacks";
import accessPoints from "./accessPoints";
import snackbars from "./snackbars";
import bookings from "./bookings";
import wifiJourneys from "./wifiJourneys";
import activeDays from "./activeDays";
import dataPackInstances from "./dataPackInstances";
import regions from "./regions";
import tags from "./tags";
import jasper from "./jasper";

const config = {
    key: "root",
    storage: storage,
    whitelist: [""],
    debug: process.env.REACT_APP_ENV === "test",
};

export default persistCombineReducers(config, {
    form: formReducer,

    user: user,
    locations: locations,
    organisations: organisations,
    collections: collections,
    campaigns: campaigns,
    users: users,
    dataPacks: dataPacks,
    accessPoints: accessPoints,
    bookings: bookings,
    snackbars: snackbars,
    wifiJourneys: wifiJourneys,
    activeDays: activeDays,
    dataPackInstances: dataPackInstances,
    regions: regions,
    tags: tags,
    jasper: jasper,
});
