import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "normalize.css";
import "./index.scss";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";

if (process.env.NODE_ENV === "development") {
    if (process.env.REACT_APP_ENV === "production") {
        window.firebase.initializeApp({
            apiKey: "AIzaSyCdOy5vhfkXFsMasXKOOwA6QE4Jeyc_yBM",
            databaseURL: "https://campable-app.firebaseio.com",
            storageBucket: "campable-app.appspot.com",
            authDomain: "campable-app.firebaseapp.com",
            messagingSenderId: "812336782115",
            projectId: "campable-app",
        });
    } else {
        window.firebase.initializeApp({
            apiKey: "AIzaSyAjENG_z_AtjWRdzAJOo0gFnhLkfd-VEtI",
            authDomain: "campable-app-test.firebaseapp.com",
            databaseURL: "https://campable-app-test.firebaseio.com",
            projectId: "campable-app-test",
            storageBucket: "campable-app-test.appspot.com",
            messagingSenderId: "611415155903",
        });
    }
}

ReactDOM.render(<App />, document.getElementById("root"));
