/**

 ▬▬ι═══════ﺤ            -═══════ι▬▬
 Created by Chris on 18/09/18.
 ▬▬ι═══════ﺤ            -═══════ι▬▬

 **/

import wrapper from "./helpers/wrapper";
import * as UsersAPI from "./api/users";
import createAction from "./helpers/createAction";

export const SET_ADMIN_USER = "SET_ADMIN_USER";
export const SET_ADMIN_USERS = "SET_ADMIN_USERS";
export const REMOVE_USER_ORG = "REMOVE_USER_ORG";

export const setUser = createAction(SET_ADMIN_USER, "user");
export const setUsers = createAction(SET_ADMIN_USERS, "users");
export const removeUserFromOrganisationRedux = createAction(REMOVE_USER_ORG, "orgId", "userId");

export const getUser = wrapper(UsersAPI.getUserAPI, (dispatch, user) => {
    dispatch(setUser(user));
});

export const getUsers = wrapper(UsersAPI.getUsersAPI, (dispatch, users) => {
    dispatch(setUsers(users.items));
});

export const getOrganisationUsers = wrapper(UsersAPI.getOrganisationUsersAPI, (dispatch, users) => {
    dispatch(setUsers(users));
});

export const sendPasswordResetToEmail = wrapper((token, email) => {
    return window.firebase.auth().sendPasswordResetEmail(email);
});

export const updateUser = wrapper(UsersAPI.updateUserAPI, (dispatch, r) => {
    dispatch(setUser(r));
});

export const deleteUser = wrapper(UsersAPI.deleteUserAPI);
