/**

 ▬▬ι═══════ﺤ            -═══════ι▬▬
 Created by Chris on 2/09/18.
 ▬▬ι═══════ﺤ            -═══════ι▬▬

 **/
import get from "./util/get";
import post from "./util/post";
import put from "./util/put";

export function getCampaignsAPI(token, params) {
    return get("v1/campaigns/search", token, params);
}

export function createCampaignAPI(token, data) {
    return post("v1/campaigns", token, data);
}

export function updateCampaignAPI(token, campaignId, data) {
    return put(`v1/campaigns/${campaignId}`, token, data);
}
