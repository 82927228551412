/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 7/11/18.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

import * as Type from "../actions/snackbars";

const initialState = [];

export default function snackbars(state = initialState, action = { type: null }) {
    switch (action.type) {
        case Type.ADD_SNACKBAR: {
            return [...state, action.config];
        }

        case Type.REMOVE_SNACKBAR: {
            return [...state].filter((snack) => {
                return snack.id !== action.id;
            });
        }
        default: {
            return state;
        }
    }
}
