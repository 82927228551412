import React from "react";
import PropTypes from "prop-types";
import styles from "./TableChip.module.scss";
import classnames from "classnames";
import MatChip from "@material-ui/core/Chip/Chip";
import omit from "lodash/omit";

export default function TableChip(props) {
    const className = classnames(
        styles.chip,
        {
            [styles.blue]: props.blue,
            [styles.green]: props.green,
            [styles.red]: props.red,
        },
        props.className,
    );

    const newProps = omit(props, Object.keys(TableChip.defaultProps), "children");

    return <MatChip label={props.children} {...newProps} className={className} />;
}

TableChip.propTypes = {
    className: PropTypes.string,

    blue: PropTypes.bool,
    green: PropTypes.bool,
    red: PropTypes.bool,
};

TableChip.defaultProps = {
    className: null,

    blue: false,
    green: false,
    red: false,
};
