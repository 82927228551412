import React, { Component } from "react";
import { connect } from "react-redux";
import styles from "./LocationSites.module.scss";
import isAdmin from "../../../../../constants/isAdmin";
import Table from "../../../../widgets/table/Table";
import values from "lodash/values";
import AddIcon from "@material-ui/icons/Add";
import LinkButton from "../../../../widgets/button/LinkButton";
import { SITE_PATH } from "../../../../../constants/routes/Routes";
import TableChip from "../../../../widgets/chip/TableChip";

class LocationSites extends Component {
    constructor(props) {
        super(props);

        this.state = {
            deleteLoading: false,
            deleteError: null,
        };

        const columns = [
            "Id",
            "Name",
            {
                name: "Status",
                options: {
                    customBodyRender: this.renderStatus,
                },
            },
            "Spaces",
            "Price",
        ];

        if (this.props.isAdmin) {
            columns.push({
                name: "",
                options: {
                    customHeadRender: this.renderCreateNewButton,
                    customBodyRender: this.renderRowEditButtons,
                },
            });
        }

        this.columns = columns;
    }

    renderCreateNewButton = () => {
        const link = SITE_PATH.replace(":locationId", this.props.locationId).replace(":siteId", "new");

        return (
            <th key={"renderAddButton"} className={styles.table_header_item}>
                <div className={styles.inner}>
                    <LinkButton to={link}>
                        <AddIcon />
                        Add
                    </LinkButton>
                </div>
            </th>
        );
    };

    renderRowEditButtons = (siteId) => {
        return (
            <div className={styles.edit_buttons}>
                <LinkButton to={`/dashboard/locations/${this.props.locationId}/sites/${siteId}`}>View</LinkButton>
            </div>
        );
    };

    renderStatus = (status) => {
        const green = status === "active";
        const red = status === "inactive";

        status = (status || "").toUpperCase();
        return (
            <TableChip green={green} red={red}>
                {status}
            </TableChip>
        );
    };

    renderTableData = () => {
        return values(this.props.sites).map((s) => {
            const columns = [s.id, s.name, s.status, s.spaces, s.price];

            if (this.props.isAdmin) {
                columns.push(s.id);
            }

            return columns;
        });
    };

    render() {
        return (
            <div className={styles.location_sites}>
                <Table
                    title={"Sites"}
                    data={this.renderTableData()}
                    columns={this.columns}
                    loading={this.state.loading}
                />
            </div>
        );
    }
}

export default LocationSites = connect((state, props) => {
    const locationId = Number(props.match.params.locationId);
    const location = state.locations[locationId] || {};
    const propertyInformation = location.propertyInformation || {};
    const sites = propertyInformation.sites || [];

    return {
        isAdmin: isAdmin(state.user),
        locationId: locationId,
        location: location,
        sites: sites,
    };
})(LocationSites);
