/**

 ▬▬ι═══════ﺤ            -═══════ι▬▬
 Created by Chris on 28/08/18.
 ▬▬ι═══════ﺤ            -═══════ι▬▬

 **/
import get from "./util/get";
import post from "./util/post";
import put from "./util/put";
import del from "./util/del";

export function getCollectionsAPI(token, params) {
    return get("v1/collections", token, params);
}

export function getCollectionAPI(token, orgId) {
    return get(`v1/collections/${orgId}`, token);
}

export function createCollectionAPI(token, data) {
    return post("v1/collections", token, data);
}

export function updateCollectionAPI(token, collectionId, data) {
    return put(`v1/collections/${collectionId}`, token, data);
}

export function getLocationsForCollectionAPI(token, collectionId) {
    return get(`v1/collections/${collectionId}/locations`, token);
}

export function addLocationToCollectionAPI(token, collectionId, locationId) {
    return post(`v1/collections/${collectionId}/locations/${locationId}`, token);
}

export function removeLocationFromCollectionAPI(token, collectionId, locationId) {
    return del(`v1/collections/${collectionId}/locations/${locationId}`, token);
}

export function addUserToCampaignAPI(token, collectionId, campaignId, email) {
    return post(`v1/collections/${collectionId}/${campaignId}/invite`, token, {
        email: email,
    });
}

export function getCampaignsForCollectionAPI(token, collectionId, params) {
    return get(`v1/collections/${collectionId}/campaigns`, token, params);
}
