import WifiJourneyHome from "../../components/routes/wifiJourney/WifiJourneyHome";
import DataPackInstances from "../../components/routes/dataPackInstances/DataPackInstances";

const WifiJourneyRoutes = [
    {
        name: "Home",
        path: "/dashboard/wifi-journeys/:wjId",
        component: WifiJourneyHome,
        isHidden: false,
        exact: true,
    },
    {
        name: "Data Packs",
        path: "/dashboard/wifi-journeys/:wjId/data-pack-instances",
        component: DataPackInstances,
        isHidden: false,
        exact: true,
    },
];

export default WifiJourneyRoutes;
