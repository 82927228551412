import React from "react";
const OrganisationUsers = React.lazy(() =>
    import("../../components/routes/organisation/routes/users/OrganisationUsers"),
);
const OrganisationDataPacks = React.lazy(() =>
    import("../../components/routes/organisation/routes/dataPacks/OrganisationDataPacks"),
);
const DataPack = React.lazy(() => import("../../components/routes/dataPack/DataPack"));
const AccessPoints = React.lazy(() => import("../../components/routes/accessPoints/AccessPoints"));
const AccessPoint = React.lazy(() => import("../../components/routes/accessPoint/AccessPoint"));
const OrgDetails = React.lazy(() => import("../../components/routes/organisation/routes/details/OrgDetails"));
const Collections = React.lazy(() => import("../../components/routes/organisation/routes/collections/Collections"));
const Collection = React.lazy(() => import("../../components/routes/organisation/routes/collection/Collection"));
const OrganisationWidgets = React.lazy(() =>
    import("../../components/routes/organisation/routes/widgets/OrganisationWidgets"),
);

const OrganisationRoutes = [
    {
        name: "Details",
        path: "/dashboard/organisations/:orgId",
        component: OrgDetails,
        isHidden: false,
        exact: true,
    },
    {
        path: "/dashboard/organisations/:orgId/access-points/:accessPointId",
        component: AccessPoint,
        isHidden: true,
        exact: true,
    },
    {
        name: "Access Points",
        path: "/dashboard/organisations/:orgId/access-points",
        component: AccessPoints,
        isHidden: false,
        exact: true,
    },
    {
        name: "Users",
        path: "/dashboard/organisations/:orgId/users",
        component: OrganisationUsers,
        isHidden: false,
        exact: true,
    },
    {
        path: "/dashboard/organisations/:orgId/collections/:collectionId",
        component: Collection,
        isHidden: true,
    },
    {
        name: "Collections",
        path: "/dashboard/organisations/:orgId/collections",
        component: Collections,
        isHidden: false,
        exact: true,
    },
    {
        path: "/dashboard/organisations/:orgId/data-packs/:dataPackId",
        component: DataPack,
        isHidden: true,
        exact: true,
        isSysAdmin: true,
    },
    {
        name: "Data Packs",
        path: "/dashboard/organisations/:orgId/data-packs",
        component: OrganisationDataPacks,
        isHidden: false,
        exact: true,
        isSysAdmin: true,
    },
    {
        name: "Widgets",
        path: "/dashboard/organisations/:orgId/widgets",
        component: OrganisationWidgets,
        isHidden: false,
        exact: true,
    },
];

export default OrganisationRoutes;
