import React, { Component } from "react";
import PropTypes from "prop-types";
import { reduxForm, reset } from "redux-form";
import TextInputField from "../../textInput/TextInputField";
import DatePickerField from "../../datePicker/DatePickerField";
import { startWifiJourney } from "../../../../redux/actions/wifiJourneys";
import { getAccessPoint } from "../../../../redux/actions/accessPoints";
import DialogV1 from "../v1/DialogV1";
import styles from "./StartWifiJourneyDialog.module.scss";

const INITIAL_STATE = {
    loading: false,
    error: null,
};

class StartWifiJourneyDialog extends Component {
    static propTypes = {
        className: PropTypes.string,
        classes: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),

        shown: PropTypes.bool.isRequired,
        onClose: PropTypes.func.isRequired,
    };

    static defaultProps = {
        className: null,
        classes: [],

        shown: false,
        onClose: () => null,
    };

    state = INITIAL_STATE;

    onSubmit = async (values) => {
        this.setState({
            loading: true,
        });
        try {
            await this.props.dispatch(startWifiJourney(this.props.accessPointId, values));
            this.refreshAccessPoint();
        } catch (err) {
            this.setState({
                loading: false,
                error: err.message,
            });
        }
    };

    refreshAccessPoint = async () => {
        try {
            await this.props.dispatch(getAccessPoint(this.props.accessPointId));
            this.setState(
                {
                    loading: false,
                },
                this.onClose,
            );
        } catch (err) {
            this.setState({
                loading: false,
                error: err.message,
            });
        }
    };

    onClose = () => {
        this.setState(INITIAL_STATE);
        this.props.onClose();
        this.props.dispatch(reset(this.props.form));
    };

    render() {
        const actions = [
            {
                text: "Cancel",
                onPress: this.onClose,
                props: {
                    plain: true,
                },
            },
            {
                text: "OK",
                props: {
                    type: "submit",
                    loading: this.state.loading,
                },
            },
        ];

        return (
            <DialogV1
                className={styles.start_wifi_journey_dialog}
                contentClassName={styles.start_wifi_journey_dialog_content}
                shown={this.props.shown}
                onClose={this.onClose}
                actions={actions}
                error={this.state.error}
                title="Start Wifi Journey"
                onSubmit={this.props.handleSubmit(this.onSubmit)}>
                <TextInputField name={"affiliateBookingId"} label={"Affiliate Booking Id"} autoFocus />
                <DatePickerField name={"estEndDate"} label={"Estimated End Date"} />
            </DialogV1>
        );
    }
}

StartWifiJourneyDialog = reduxForm({
    form: "startWifiJourney",
    enableReinitialize: true,
    validate: (values, props) => {
        const errors = {};

        if (!props.pristine) {
            if (!values.name || values.name === "") {
                errors.name = "Required";
            }
        }

        return errors;
    },
})(StartWifiJourneyDialog);

export default StartWifiJourneyDialog;
