/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 22/11/18.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

import React, { Component } from "react";
import { connect } from "react-redux";
import styles from "./Locations.module.scss";
import { getLocations } from "../../../redux/actions/locations";
import Table from "../../widgets/table/Table";
import LinkButton from "../../widgets/button/LinkButton";
import DataTableCursorFooter from "../../../constants/tableFooters/DataTableCursorFooter";

class Locations extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            rowsPerPage: 10,
            pageIndex: 0,
            page: {},
            nav: {},
            rowsSelected: [],
            columns: [
                {
                    key: "id",
                    name: "ID",
                    options: {
                        display: true,
                        filter: false,
                    },
                },
                {
                    name: "Name",
                    options: {
                        display: true,
                        filter: false,
                    },
                },
                {
                    name: "Address",
                    options: {
                        display: true,
                        filter: false,
                    },
                },
                {
                    key: "type",
                    name: "Type",
                    options: {
                        display: true,
                        filter: true,
                    },
                },
                {
                    key: "status",
                    name: "Status",
                    options: {
                        display: true,
                        filter: true,
                    },
                },
                {
                    key: "public",
                    name: "Public",
                    options: {
                        display: true,
                        filter: true,
                    },
                },
                {
                    name: "",
                    options: {
                        display: true,
                        customBodyRender: this.renderViewButton,
                        filter: false,
                    },
                },
            ],
        };
    }

    componentDidMount() {
        this.getLocations();
    }

    getPageData = () => {
        const page = this.state.page;
        const items = page.items || [];
        const ids = items.map((item) => item.id);
        return ids.map((id) => this.props.store[id]);
    };

    renderTableData = () => {
        return this.getPageData().map((location) => {
            return [
                location.id,
                location.name,
                location.address,
                location.type,
                location.status && location.status.toUpperCase(),
                typeof (location === 'boolean') && location.isPublic.toString().toUpperCase(),
                location.id,
            ];
        });
    };

    getFilterValue = (key) => {
        const column = this.state.columns.find((col) => col.key === key);
        if (!column) console.error(`Can't find column: ${key}`);
        return (column.options.filterList || [])[0];
    };

    getLocations = async () => {
        this.setState({
            loading: true,
        });
        try {
            const params = {
                cursor: this.state.nav.cursor,
                limit: this.state.rowsPerPage,
            };
            if (this.state.searchText) {
                params.freeFormQuery = this.state.searchText;
            }
            const type = this.getFilterValue("type");
            if (type) {
                params.type = type;
            }
            const status = this.getFilterValue("status");
            if (status) {
                params.status = status;
            }
            const page = await this.props.dispatch(getLocations(params));
            this.setState({
                loading: false,
                page: page,
            });
        } catch (err) {
            this.setState({
                loading: false,
                error: err.message,
            });
        }
    };

    renderViewButton = (locationId) => {
        return (
            <div className={styles.view_buttons}>
                <LinkButton to={`/dashboard/locations/${locationId}`}>View</LinkButton>
            </div>
        );
    };

    onChangePage = (pageIndex) => {
        if (this.state.loading) {
            return;
        }
        this.setState((prevState) => {
            let nav;
            if (pageIndex > prevState.pageIndex) {
                nav = {
                    prevNav: prevState.nav,
                    cursor: prevState.page.cursor,
                };
            } else {
                nav = prevState.nav.prevNav;
            }
            return {
                nav: nav,
                pageIndex: pageIndex,
                loading: true,
            };
        }, this.getLocations);
    };

    onChangeRowsPerPage = (rowsPerPage) => {
        this.setState(
            {
                rowsPerPage: rowsPerPage,
            },
            this.getLocations,
        );
    };

    onColumnViewChange = (changedColumn, action) => {
        this.setState((prevState) => {
            const columns = [...prevState.columns];
            const i = columns.findIndex((col) => col.name === changedColumn);
            const column = columns[i];
            columns[i] = {
                ...column,
                options: {
                    ...column.options,
                    display: action === "add",
                },
            };
            return {
                columns: columns,
            };
        }, this.getLocations);
    };

    onSearchChange = (searchText) => {
        this.setState(
            {
                searchText: searchText,
            },
            this.getLocations,
        );
    };

    onFilterChange = (changedColumn, filterList) => {
        this.setState(
            (prevState) => ({
                columns: prevState.columns.map((col, i) => ({
                    ...col,
                    options: {
                        ...col.options,
                        filterList: filterList[i],
                    },
                })),
            }),
            this.getLocations,
        );
    };

    render() {
        const data = this.renderTableData();
        const count = this.state.pageIndex * this.state.rowsPerPage + data.length;
        const end = !this.state.page.cursor;
        const options = {
            filterType: "dropdown",
            responsive: "stacked",
            serverSide: true,
            customFooter: DataTableCursorFooter(end),
            search: true,
            sort: false,
            count: count,
            page: this.state.pageIndex,
            rowsPerPage: this.state.rowsPerPage,
            onChangeRowsPerPage: this.onChangeRowsPerPage,
            onChangePage: this.onChangePage,
            onSearchChange: this.onSearchChange,
            onFilterChange: this.onFilterChange,
            onColumnViewChange: this.onColumnViewChange,
        };

        return (
            <div className={styles.root}>
                <Table
                    options={options}
                    loading={this.state.loading}
                    title={"Locations"}
                    data={data}
                    columns={this.state.columns}
                />
            </div>
        );
    }
}

export default Locations = connect((state, props) => {
    return {
        store: state.locations,
    };
})(Locations);
