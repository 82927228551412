import AccessPointHome from "../../components/routes/accessPoint/AccessPointHome";
import WifiJourneys from "../../components/routes/wifiJourneys/WifiJourneys";
import ActiveDays from "../../components/routes/activeDays/ActiveDays";

const AccessPointRoutes = [
    {
        name: "Details",
        path: "/dashboard/access-points/:apId",
        component: AccessPointHome,
        isHidden: false,
        exact: true,
    },
    {
        name: "Wifi Journeys",
        path: "/dashboard/access-points/:apId/wifi-journeys",
        component: WifiJourneys,
        isHidden: false,
        exact: true,
    },
    {
        name: "Active Days",
        path: "/dashboard/access-points/:apId/active-days",
        component: ActiveDays,
        isHidden: false,
        exact: true,
    },
];

export default AccessPointRoutes;
