/**

 ▬▬ι═══════ﺤ            -═══════ι▬▬
 Created by Chris on 24/08/18.
 ▬▬ι═══════ﺤ            -═══════ι▬▬

 **/
import get from "./util/get";

export function getUserAPI(token, userId) {
    return get(`v1/users/${userId}`, token);
}
