/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 22/11/18.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */
import get from "./util/get";
import put from "./util/put";
import del from "./util/del";

export function getDataPacksForOrganisationAPI(token, orgId) {
    return get(`v1/organisations/${orgId}/data-packs`, token);
}

export function createOrUpdateDataPackAPI(token, data) {
    return put("v1/data-packs", token, data);
}

export function deleteDataPackAPI(token, dpId) {
    return del(`v1/data-packs/${dpId}`, token);
}
