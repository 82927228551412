import * as Type from "../actions/wifiJourneys";

export default function wifiJourneys(state = {}, action) {
    switch (action.type) {
        case Type.SET_WIFI_JOURNEY: {
            return {
                ...state,
                [action.wifiJourney.id]: action.wifiJourney,
            };
        }

        case Type.SET_WIFI_JOURNEYS: {
            const newState = { ...state };
            action.items.forEach((item) => {
                newState[item.id] = item;
            });
            return newState;
        }

        default:
            return state;
    }
}
