/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 11/11/18.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

import * as Type from "../actions/bookings";

const initialState = {
    bookings: {},
    guests: {},
    hosts: {},
};

export default function bookings(state = initialState, action = { type: null }) {
    switch (action.type) {
        case Type.SET_BOOKING: {
            const booking = action.booking;

            const bookings = { ...state.bookings };

            bookings[booking.id] = booking;

            const guest = state.hosts[booking.guest] || {};
            const host = state.hosts[booking.host] || {};

            guest[booking.id] = booking.id;
            host[booking.id] = booking.id;

            return {
                bookings: bookings,
                guests: {
                    ...state.guests,
                    [booking.guest]: guest,
                },
                hosts: {
                    ...state.hosts,
                    [booking.host]: host,
                },
            };
        }
        case Type.SET_BOOKINGS: {
            const bookings = { ...state.bookings };
            const guests = { ...state.guests };
            const hosts = { ...state.hosts };

            (action.bookings || []).map((booking) => {
                bookings[booking.id] = booking;

                const guest = guests[booking.guest] || {};
                const host = hosts[booking.host] || {};

                guest[booking.id] = booking.id;
                host[booking.id] = booking.id;

                guests[booking.guest] = guest;
                hosts[booking.host] = host;
                return null;
            });

            return {
                bookings: bookings,
                guests: guests,
                hosts: hosts,
            };
        }
        default: {
            return state;
        }
    }
}
