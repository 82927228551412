/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 7/11/18.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

import React, { Component } from "react";
import PropTypes from "prop-types";
import styles from "./Snackbar.module.scss";
import classnames from "classnames";
import MatSnackbar from "@material-ui/core/Snackbar";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import IconButton from "@material-ui/core/IconButton";
import Fade from "@material-ui/core/Fade";
import CloseIcon from "@material-ui/icons/Close";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ErrorIcon from "@material-ui/icons/Error";
import InfoIcon from "@material-ui/icons/Info";
import WarningIcon from "@material-ui/icons/Warning";

export default class Snackbar extends Component {
    static propTypes = {
        className: PropTypes.string,
        classes: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),

        snackbarShown: PropTypes.bool,
        autoHideDuration: PropTypes.number,
        onHandleClose: PropTypes.func,

        config: PropTypes.shape({
            id: PropTypes.string,
            text: PropTypes.string,
            success: PropTypes.bool,
            error: PropTypes.bool,
            warning: PropTypes.bool,
        }),
    };

    static defaultProps = {
        className: null,
        classes: [],

        snackbarShown: false,
        onHandleClose: () => null,

        config: {
            id: null,
            icon: null,
            text: null,
            success: false,
            error: false,
            warning: false,
            disableAutoHide: false,
            duration: 2500,
        },
    };

    onHandleClose = () => {
        this.props.onHandleClose(this.props.config);
    };

    render() {
        const config = this.props.config || {};
        const className = classnames(
            styles.snackbar_content,
            {
                [styles.success]: config.success,
                [styles.error]: config.error,
                [styles.warning]: config.warning,
            },
            this.props.className,
            this.props.classes,
        );

        let Icon = null;

        if (config.success) {
            Icon = CheckCircleIcon;
        } else if (config.warning) {
            Icon = WarningIcon;
        } else if (config.warning) {
            Icon = ErrorIcon;
        } else {
            Icon = InfoIcon;
        }

        if (config.icon) {
            Icon = config.icon;
        }

        return (
            <MatSnackbar
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                className={styles.snackbar}
                open={this.props.snackbarShown}
                TransitionComponent={Fade}
                ClickAwayListenerProps={{ touchEvent: false, mouseEvent: false }}
                autoHideDuration={config.duration ? config.duration : 2500}
                onClose={this.onHandleClose}
                key={this.props.config.id}
                style={{ top: 90, right: 18, position: "fixed" }}>
                <SnackbarContent
                    className={className}
                    aria-describedby="client-snackbar"
                    message={
                        <span id="client-snackbar" className={styles.snackbar_content_message}>
                            {Icon ? <Icon /> : null}
                            <span>{config.text}</span>
                        </span>
                    }
                    action={[
                        <IconButton key="close" aria-label="Close" color="inherit" onClick={this.onHandleClose}>
                            <CloseIcon />
                        </IconButton>,
                    ]}
                />
            </MatSnackbar>
        );
    }
}
