/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 2018-12-12.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

import { store } from "../App";
import isAdmin from "./isAdmin";
import isSysAdmin from "./isSysAdmin";
import isInOrg from "./isInOrg";
import isOrgAdmin from "./isOrgAdmin";

export default function filterRoutes(routes = [], user = null, orgId = null) {
    if (!user) {
        user = store.getState().user;
    }

    return routes.filter((route) => {
        if (!!route.isSysAdmin && !isSysAdmin(user)) {
            return false;
        } else if (!!route.isAdmin && !isAdmin(user)) {
            return false;
        } else if (!!orgId && !!route.isOrgAdmin && !isOrgAdmin(orgId, user)) {
            return false;
        } else if (!!orgId && !isInOrg(orgId, user)) {
            return false;
        }

        return true;
    });
}
