/**

 ▬▬ι═══════ﺤ            -═══════ι▬▬
 Created by Chris on 24/08/18.
 ▬▬ι═══════ﺤ            -═══════ι▬▬

 **/

import createAction from "./helpers/createAction";
import wrapper from "./helpers/wrapper";
import * as UsersAPI from "./api/user";

export const SET_USER = "SET_USER";
export const REMOVE_USER = "REMOVE_USER";

export const setUser = createAction(SET_USER, "user");

export const removeUser = createAction(REMOVE_USER);

export const getUser = wrapper(UsersAPI.getUserAPI, (dispatch, user) => {
    dispatch(setUser(user));
});

export const userLogOut = () => {
    return (dispatch) => {
        window.firebase
            .auth()
            .signOut()
            .then(() => {
                dispatch(removeUser());
            })
            .catch((err) => {
                console.log("LOG OUT ERROR", err);
            });
    };
};
