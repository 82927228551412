/**

 ▬▬ι═══════ﺤ            -═══════ι▬▬
 Created by Chris on 25/08/18.
 ▬▬ι═══════ﺤ            -═══════ι▬▬

 **/
import getToken from "./helpers/getAuthToken";
import { get, post, put, del } from "./api/util/request";

import { removeUserFromOrganisationRedux, setUser } from "./users";

export const SET_ORGANISATIONS = "SET_ORGANISATIONS";
export const SET_ORGANISATION = "SET_ORGANISATION";
export const REMOVE_ORGANISATION = "REMOVE_ORGANISATION";

export const getOrganisations = (query) => {
    query = query || {};
    query.limit = query.limit || 999;
    return async (dispatch) => {
        const token = await getToken();
        const result = await get("v1/organisations", token, query);
        dispatch({
            type: SET_ORGANISATIONS,
            organisations: result.items,
        });
        return result;
    };
};

export const getOrganisation = (orgId) => {
    return async (dispatch) => {
        const token = await getToken();
        const organisation = await get(`v1/organisations/${orgId}`, token);
        dispatch({
            type: SET_ORGANISATION,
            organisation: organisation,
        });
        return organisation;
    };
};

export const putOrganisation = (org) => {
    return async (dispatch) => {
        const token = await getToken();
        const organisation = await put("v1/organisations", token, org);
        dispatch({
            type: SET_ORGANISATION,
            organisation: organisation,
        });
        return organisation;
    };
};

export const deleteOrganisation = (orgId) => {
    return async (dispatch) => {
        const token = await getToken();
        await del(`v1/organisations/${orgId}`, token);
        dispatch({
            type: REMOVE_ORGANISATION,
            orgId: orgId,
        });
    };
};

export const addOrUpdateUserToOrganisation = ({ orgId, email, role }) => {
    const data = {
        email: email,
        role: role,
    };
    return async (dispatch) => {
        const token = await getToken();
        const user = await post(`v1/organisations/${orgId}/users`, token, data);
        dispatch(setUser(user));
        return user;
    };
};

export const removeUserFromOrganisation = ({ orgId, userId }) => {
    return async (dispatch) => {
        const token = await getToken();
        const user = await del(`v1/organisations/${orgId}/users/${userId}`, token);
        dispatch(setUser(user));
        dispatch(removeUserFromOrganisationRedux(orgId, userId));
        return user;
    };
};

export const getOrganisationsForUser = (userId) => {
    return async (dispatch) => {
        const token = await getToken();
        const organisations = await get(`v1/organisations/user/${userId}`, token);
        dispatch({
            type: SET_ORGANISATIONS,
            organisations: organisations,
        });
        return organisations;
    };
};
