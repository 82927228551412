import React, { Component } from "react";
// import '../../../App.css';
import { Circle, GoogleMap, withGoogleMap } from "react-google-maps";

const Map = withGoogleMap((props) => (
    <GoogleMap ref={props.onMapLoad} defaultZoom={props.zoom} {...props}>
        {props.children}
    </GoogleMap>
));

export default class Mapp extends Component {
    constructor(props) {
        super(props);

        this.handleMapLoad = this.handleMapLoad.bind(this);
    }

    handleMapLoad(map) {
        this.map = map;

        if (this.props.setRef) {
            this.props.setRef(map);
        }
    }

    render() {
        let centerPin = null;

        if (this.props.hasCenterPin) {
            centerPin = (
                <Circle
                    key={"center-pin"}
                    center={{ lat: this.props.center.lat, lng: this.props.center.lng }}
                    options={{ fillColor: "#5fce17", fillOpacity: 0.4, strokeColor: "#5fce17" }}
                    radius={4000}
                />
            );
        }

        let children = [centerPin];

        if (this.props.children) {
            children = children.concat(this.props.children);
        }

        let zoom = 12;
        if (this.props.zoom) {
            zoom = this.props.zoom;
        }

        return (
            <Map
                containerElement={<div className={this.props.containerClass} style={this.props.containerStyle} />}
                mapElement={<div className={this.props.mapClass} style={this.props.mapStyle} />}
                defaultCenter={this.props.center}
                defaultZoom={zoom}
                onMapLoad={this.handleMapLoad}
                {...this.props}>
                {children}
            </Map>
        );
    }
}
