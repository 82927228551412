/**

 ▬▬ι═══════ﺤ            -═══════ι▬▬
 Created by Chris on 24/08/18.
 ▬▬ι═══════ﺤ            -═══════ι▬▬

 **/

import * as Type from "../actions/users";

const initialState = {
    users: {},
    organisations: {},
};

export default (state = initialState, action) => {
    switch (action.type) {
        case Type.SET_ADMIN_USERS: {
            const users = { ...state.users };
            const organisations = { ...state.organisations };

            (action.users || []).map((user) => {
                users[user.id] = user;
                (user.userRoles || []).map((userRole) => {
                    if (!!userRole && !!userRole.organisation) {
                        organisations[userRole.organisation] = {
                            ...(organisations[userRole.organisation] || {}),
                            [user.id]: { ...userRole, user: user.id },
                        };
                    }
                    return null;
                });

                return null;
            });

            return {
                ...state,
                users: users,
                organisations: organisations,
            };
        }

        case Type.SET_ADMIN_USER: {
            const user = action.user || {};
            const organisations = { ...state.organisations };

            (user.userRoles || []).map((userRole) => {
                if (!!userRole && !!userRole.organisation) {
                    organisations[userRole.organisation] = {
                        ...(organisations[userRole.organisation] || {}),
                        [user.id]: { ...userRole, user: user.id },
                    };
                }
                return null;
            });

            return {
                ...state,
                users: {
                    ...state.users,
                    [user.id]: user,
                },
                organisations: organisations,
            };
        }

        case Type.REMOVE_USER_ORG: {
            const userId = action.userId;
            const orgId = action.orgId;
            const organisations = { ...state.organisations };

            const organisation = organisations[orgId] || {};
            delete organisation[userId];

            return {
                ...state,
                organisations: organisations,
            };
        }

        default: {
            return {
                ...state,
            };
        }
    }
};
