import React, { Component } from "react";
import { connect } from "react-redux";
import Table from "../../widgets/table/Table";
import { getWifiJourneys } from "../../../redux/actions/wifiJourneys";
import { getOrganisations } from "../../../redux/actions/organisations";
import { getAccessPoint } from "../../../redux/actions/accessPoints";
import moment from "moment";
import LinkButton from "../../widgets/button/LinkButton";
import styles from "./WifiJourneys.module.scss";
import { Link } from "react-router-dom";

class WifiJourneys extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            page: {},
        };
    }

    componentDidMount() {
        this.getAccessPoint();
        this.getOrganisations();
        this.getWifiJourneys();
    }

    getColumns = () => {
        return [
            {
                name: "Start Date",
                options: {
                    sortDirection: "desc",
                },
            },
            "End Date",
            "Est End Date",
            "Booking ID",
            {
                name: "Organisation",
                options: {
                    display: true,
                    customBodyRender: this.renderOrgCell,
                    filter: false,
                },
            },
            {
                name: "Access Point",
                options: {
                    customBodyRender: this.renderAccessPointCell,
                },
            },
            {
                name: "",
                options: {
                    customBodyRender: this.renderRowEditButtons,
                },
            },
        ];
    };

    renderOrgCell = (orgId) => {
        const org = this.props.organisations[orgId] || {};
        return <Link to={`/dashboard/organisations/${orgId}`}>{org.name}</Link>;
    };

    renderAccessPointCell = (apId) => {
        return <Link to={`/dashboard/access-points/${apId}`}>{apId}</Link>;
    };

    renderRowEditButtons = (wjId) => {
        return (
            <div className={styles.edit_buttons}>
                <LinkButton to={`/dashboard/wifi-journeys/${wjId}`}>View</LinkButton>
            </div>
        );
    };

    getOrganisations = async () => {
        this.props.dispatch(getOrganisations());
    };

    getAccessPoint = async () => {
        const apId = this.props.apId;
        if (!apId) {
            return;
        }
        this.setState({
            apLoading: true,
        });
        try {
            await this.props.dispatch(getAccessPoint(apId));
            this.setState({
                apLoading: false,
                journeyStatusLoading: false,
            });
        } catch (err) {
            this.setState({
                apLoading: false,
                journeyStatusLoading: false,
                error: err.message,
            });
        }
    };

    getWifiJourneys = async () => {
        const apId = this.props.apId;
        const orgId = this.props.orgId;
        if (!apId && !orgId) {
            return;
        }
        this.setState({
            loading: true,
        });
        try {
            const params = {
                limit: 999,
            };
            if (apId) {
                params.accessPoint = apId;
            }
            if (orgId) {
                params.organisation = orgId;
            }
            const page = await this.props.dispatch(getWifiJourneys(params));
            this.setState({
                loading: false,
                page: page,
            });
        } catch (err) {
            this.setState({
                loading: false,
                error: err.message,
            });
        }
    };

    renderTableData = () => {
        const page = this.state.page;
        const items = page.items || [];
        const wifiJourneyIds = items.map((item) => item.id);
        const wifiJourneys = wifiJourneyIds.map((id) => this.props.store[id]);
        const ap = this.props.accessPoint || {};
        const activeWifiJourney = ap.activeWifiJourney || {};

        return wifiJourneys.map((wj) => {
            const startDate = wj.startDate ? moment(wj.startDate).format("YYYY-MM-DD") : "";
            const endDate = wj.endDate
                ? moment(wj.endDate).format("YYYY-MM-DD")
                : activeWifiJourney.id === wj.id
                ? "ACTIVE"
                : "";
            const estEndDate = wj.estEndDate ? moment(wj.estEndDate).format("YYYY-MM-DD") : "";
            return [startDate, endDate, estEndDate, wj.affiliateBookingId, wj.organisation, wj.accessPoint, wj.id];
        });
    };

    render() {
        const options = {
            filter: false,
            responsive: "stacked",
        };
        return (
            <div className={styles.root}>
                <Table
                    options={options}
                    loading={this.state.loading}
                    title={"Wifi Journeys"}
                    data={this.renderTableData()}
                    columns={this.getColumns()}
                />
            </div>
        );
    }
}

export default WifiJourneys = connect((state, props) => {
    const apId = Number(props.match.params.apId);
    const orgId = Number(props.match.params.orgId);
    const accessPoint = state.accessPoints[apId];
    return {
        apId: apId,
        orgId: orgId,
        store: state.wifiJourneys,
        organisations: state.organisations,
        accessPoint: accessPoint,
    };
})(WifiJourneys);
