/**

 ▬▬ι═══════ﺤ            -═══════ι▬▬
 Created by Chris on 25/08/18.
 ▬▬ι═══════ﺤ            -═══════ι▬▬

 **/
import createAction from "./helpers/createAction";
import wrapper from "./helpers/wrapper";
import * as CollectionsAPI from "./api/collections";
import { setCampaign, setCampaigns } from "./campaigns";

export const SET_COLLECTIONS = "SET_COLLECTIONS";
export const SET_COLLECTION = "SET_COLLECTION";

export const SET_COLLECTION_LOCATIONS = "collections:SET_COLLECTION_LOCATIONS";
export const SET_COLLECTION_LOCATION = "collections:SET_COLLECTION_LOCATION";
export const REMOVE_COLLECTION_LOCATION = "collections:REMOVE_COLLECTION_LOCATION";

export const setCollections = createAction(SET_COLLECTIONS, "collections");
export const setCollection = createAction(SET_COLLECTION, "collection");

export const setCollectionLocations = createAction(SET_COLLECTION_LOCATIONS, "locations");
export const setCollectionLocation = createAction(SET_COLLECTION_LOCATION, "location");

export const getCollections = wrapper(CollectionsAPI.getCollectionsAPI, (dispatch, r) => {
    dispatch(setCollections(r.items));
});

export const getCollection = wrapper(CollectionsAPI.getCollectionAPI, (dispatch, r) => {
    dispatch(setCollection(r));
});

export const createCollection = wrapper(CollectionsAPI.createCollectionAPI, (dispatch, r) => {
    dispatch(setCollection(r));
});

export const updateCollection = wrapper(CollectionsAPI.updateCollectionAPI, (dispatch, r) => {
    dispatch(setCollection(r));
});

export const getLocationsForCollection = wrapper(CollectionsAPI.getLocationsForCollectionAPI, (dispatch, locations) => {
    dispatch(setCollectionLocations(locations));
});

export const addLocationToCollection = wrapper(CollectionsAPI.addLocationToCollectionAPI, (dispatch, location) => {
    dispatch(setCollectionLocation(location));
});

export const removeLocationFromCollectionRedux = createAction(REMOVE_COLLECTION_LOCATION, "collectionId", "locationId");

export const removeLocationFromCollection = wrapper(
    CollectionsAPI.removeLocationFromCollectionAPI,
    (dispatch, r, collectionId, locationId) => {
        dispatch(removeLocationFromCollectionRedux(collectionId, locationId));
    },
);

export const addUserToCampaign = wrapper(CollectionsAPI.addUserToCampaignAPI, (dispatch, r) => {
    dispatch(setCampaign(r.campaign));
});

export const getCampaignsForCollection = wrapper(CollectionsAPI.getCampaignsForCollectionAPI, (dispatch, r) => {
    dispatch(setCampaigns(r.items));
});
