import React, { Component } from "react";
import "airbnb-browser-shims";
import "airbnb-js-shims";
import "./constants/prototypes";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import configureStore from "./redux/store/configureStore";
import Main from "./main/Main";
import { BrowserRouter as Router } from "react-router-dom";
import CssBaseline from "@material-ui/core/CssBaseline";
import { createGenerateClassName, createMuiTheme, jssPreset, MuiThemeProvider } from "@material-ui/core/styles";
import JssProvider from "react-jss/lib/JssProvider";
import { create } from "jss";
import { Route } from "react-router";
import SnackbarContainer from "./components/widgets/snackbar/SnackbarContainer";

export const { store, persistor } = configureStore();
const styleNode = document.createComment("insertion-point-jss");
document.head.insertBefore(styleNode, document.head.firstChild);

const generateClassName = createGenerateClassName();
const jss = create(jssPreset());
jss.options.insertionPoint = "insertion-point-jss";

const theme = createMuiTheme({
    typography: {
        fontFamily: ["Museo Sans", "museo-sans", "Source Sans Pro", "Helvetica", "Arial", "sans-serif"].join(","),
    },
    overrides: {
        MuiButton: {
            root: {
                fontWeight: "bold",
            },
        },
    },
});

class App extends Component {
    render() {
        return (
            <Provider store={store}>
                <PersistGate loading={null} persistor={persistor}>
                    <JssProvider jss={jss} generateClassName={generateClassName}>
                        <MuiThemeProvider theme={theme}>
                            <CssBaseline />
                            <SnackbarContainer>
                                <Router>
                                    <Route path={"*"} component={Main} />
                                </Router>
                            </SnackbarContainer>
                        </MuiThemeProvider>
                    </JssProvider>
                </PersistGate>
            </Provider>
        );
    }
}

export default App;
