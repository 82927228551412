import * as Type from "../actions/activeDays";

export default function reduce(state = {}, action) {
    switch (action.type) {
        case Type.SET_ACTIVE_DAYS: {
            const newState = { ...state };
            action.items.forEach((item) => {
                newState[item.id] = item;
            });
            return newState;
        }

        default: {
            return state;
        }
    }
}
