/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 7/11/18.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import Snackbar from "./Snackbar";
import { removeSnackbar } from "../../../redux/actions/snackbars";
import isEqual from "lodash/isEqual";

class SnackbarContainer extends Component {
    state = {
        snackbarShown: false,
        current: null,
    };

    onHandleClose = (config) => {
        this.props.dispatch(removeSnackbar(config.id));

        this.setState(
            {
                snackbarShown: false,
            },
            () => {
                setTimeout(() => {
                    this.setState({
                        current: null,
                    });
                }, 250);
            },
        );
    };

    static getDerivedStateFromProps(props, state) {
        if (!isEqual(props.current, state.current) && state.current === null) {
            if (props.current) {
                return {
                    snackbarShown: true,
                    current: props.current,
                };
            }
        }
        return null;
    }

    render() {
        return (
            <Fragment>
                {this.props.children}
                <Snackbar
                    onHandleClose={this.onHandleClose}
                    config={this.state.current || {}}
                    snackbarShown={this.state.snackbarShown}
                />
            </Fragment>
        );
    }
}

export default SnackbarContainer = connect((state) => {
    return {
        current: state.snackbars[0],
    };
})(SnackbarContainer);
