import React, { Component } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import styles from "./CheckBoxMultiSelect.module.scss";
import { FormControl, FormHelperText } from "@material-ui/core";
import FormControlLabel from "@material-ui/core/es/FormControlLabel";
import Checkbox from "@material-ui/core/es/Checkbox";

export default class MultiSelect extends Component {
    static propTypes = {
        className: PropTypes.string,
        options: PropTypes.array,
        value: PropTypes.array,
        label: PropTypes.string,
        onValueChange: PropTypes.func,
        disabled: PropTypes.bool,
        error: PropTypes.string,
    };

    static defaultProps = {
        className: null,
        options: [],
        value: [],
        label: "",
        onValueChange: null,
        disabled: false,
        error: null,
    };

    state = {
        options: this.props.options,
        value: this.props.input.value,
        focused: false,
    };

    onValueChange = (name) => (e) => {
        const checked = e.target.checked;

        this.setState((prevState) => {
            let value = prevState.value || this.props.input.value || [];
            value = value.filter((x) => x !== name);
            this.props.onValueChange && this.props.onValueChange(value);
            this.props.input && this.props.input.onChange(value);
            if (checked) {
                value.push(name);
            }
            return { value: value };
        });
    };

    render() {
        const value = this.state.value || this.props.input.value;

        let error = this.props.error;

        if (this.props.meta) {
            error = this.props.meta.touched && this.props.meta.error;
        }

        const options = this.props.options;

        const menuItems = options.map((o) => (
            <FormControlLabel
                key={o.value}
                className={styles.formControlLabel}
                control={
                    <Checkbox
                        checked={value.includes(o.value)}
                        onChange={this.onValueChange(o.value)}
                        value={o.value}
                    />
                }
                label={o.label}
            />
        ));

        return (
            <FormControl className={classnames(styles.formControl, this.props.className)}>
                <h4>{this.props.label}</h4>
                <div>{menuItems}</div>
                <FormHelperText hidden={!error} error>
                    {error}
                </FormHelperText>
            </FormControl>
        );
    }
}
