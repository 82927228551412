/**

 ▬▬ι═══════ﺤ            -═══════ι▬▬
 Created by Chris on 17/09/18.
 ▬▬ι═══════ﺤ            -═══════ι▬▬

 **/

import { store } from "../App";

export default function isSysAdmin(user) {
    if (!user) {
        user = store.getState().user;
    }

    return (user || { roles: [] }).roles.includes("system_admin");
}
