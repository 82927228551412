import * as Type from "../../actions/jasper";

const initialState = [];

export default function commsPlans(state = initialState, action) {
    switch (action.type) {
        case Type.SET_COMMS_PLANS: {
            return action.data;
        }
        default:
            return state;
    }
}
