/**

 ▬▬ι═══════ﺤ            -═══════ι▬▬
 Created by Chris on 24/08/18.
 ▬▬ι═══════ﺤ            -═══════ι▬▬

 **/

import React, { Component } from "react";
import { connect } from "react-redux";
import styles from "./Login.module.scss";
import Card from "@material-ui/core/Card";
import Collapse from "@material-ui/core/Collapse";
import Button from "../../widgets/button/Button";
import TextInputField from "../../widgets/textInput/TextInputField";
import { reduxForm } from "redux-form";
import validateEmail from "../../../constants/validateEmail";
import ErrorField from "../../widgets/error/ErrorField";
import Loader from "../../widgets/loader/Loader";
import CampableLogo from "../../../resources/images/CampableLogo";
import { getUser } from "../../../redux/actions/user";
import { getOrganisationsForUser } from "../../../redux/actions/organisations";

class Login extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
        };
    }

    componentWillMount() {
        const onAuthStateChangedCallback = window.firebase.auth().onAuthStateChanged((user) => {
            if (user) {
                this.getUserDetails(user);
            } else {
                this.setState({
                    loading: false,
                });
            }
            onAuthStateChangedCallback();
        });
    }

    onLoginSubmit = (values) => {
        this.setState({
            loading: true,
            error: null,
        });

        window.firebase
            .auth()
            .signInWithEmailAndPassword(values.email, values.password)
            .then((user) => {
                this.getUserDetails(user.user);
            })
            .catch((err) => {
                this.setState({
                    loading: false,
                    error: err.message,
                });
            });
    };

    getUserDetails = (user) => {
        this.props.dispatch(getOrganisationsForUser(user.uid));

        this.props.dispatch(
            getUser({
                userId: user.uid,
            }),
        );
    };

    render() {
        const loading = this.state.loading;
        return (
            <div className={styles.login_container}>
                <div className={styles.banner_logo}>
                    <CampableLogo />
                </div>

                <Card className={styles.login_content_container}>
                    <Collapse in={!loading}>
                        <form onSubmit={this.props.handleSubmit(this.onLoginSubmit)} className={styles.login_form}>
                            <div className={styles.login_input}>
                                <TextInputField name={"email"} label={"Email"} />
                                <TextInputField name={"password"} label={"Password"} type={"password"} />
                            </div>
                            <ErrorField error={this.state.error} />

                            <Button type={"submit"} disabled={loading}>
                                Log In
                            </Button>
                        </form>
                    </Collapse>
                    <Collapse in={loading}>
                        <Loader />
                    </Collapse>
                </Card>
            </div>
        );
    }
}

Login = reduxForm({
    form: "login",
    validate: (values, props) => {
        const errors = {};

        if (!props.pristine) {
            if (!values.email || values.email === "") {
                errors.email = "Required.";
            }

            if (!!values.email && !validateEmail(values.email)) {
                errors.email = "Email address is badly formatted.";
            }

            if (!values.password || values.password === "") {
                errors.password = "Required.";
            }
        }

        return errors;
    },
})(Login);

export default Login = connect((state) => {
    return {
        user: state.user,
    };
})(Login);
