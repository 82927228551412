/**

 ▬▬ι═══════ﺤ            -═══════ι▬▬
 Created by Chris on 18/09/18.
 ▬▬ι═══════ﺤ            -═══════ι▬▬

 **/
import get from "./util/get";
import put from "./util/put";
import del from "./util/del";

export function getUserAPI(token, userId) {
    return get(`v1/users/${userId}`, token);
}

export function getUsersAPI(token, params) {
    return get("v1/users", token, params);
}

export function getOrganisationUsersAPI(token, orgId, params) {
    return get(`v1/organisations/${orgId}/users`, token, params);
}

export function updateUserAPI(token, userId, data) {
    return put(`v1/admin/user/update/${userId}`, token, data);
}

export function deleteUserAPI(token, userId) {
    return del(`v1/admin/user/${userId}`, token);
}
