/**

 ▬▬ι═══════ﺤ            -═══════ι▬▬
 Created by Chris on 24/08/18.
 ▬▬ι═══════ﺤ            -═══════ι▬▬

 **/

import React, { Component } from "react";
import PropTypes from "prop-types";
import styles from "./TextInput.module.scss";
import classnames from "classnames";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import omit from "lodash/omit";
import fromLowestCurrencyFormatter from "../../../constants/localization/currencies/fromLowestCurrencyFormatter";
import toLowestCurrencyFormatter from "../../../constants/localization/currencies/toLowestCurrencyFormatter";

export default class TextInput extends Component {
    static propTypes = {
        className: PropTypes.string,

        text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        label: PropTypes.string,
        onTextChange: PropTypes.func,
        formatOutput: PropTypes.func,
        formatInput: PropTypes.func,

        currencyCode: PropTypes.string,
        upperCase: PropTypes.bool,
        lowerCase: PropTypes.bool,
        disabled: PropTypes.bool,
        readOnly: PropTypes.bool,

        email: PropTypes.bool,
        phone: PropTypes.bool,
        dollar: PropTypes.bool,
        number: PropTypes.bool,
        type: PropTypes.string,
        error: PropTypes.string,
        max: PropTypes.number,
    };

    static defaultProps = {
        className: null,

        text: "",
        label: "",
        onTextChange: null,
        formatOutput: null,
        formatInput: null,

        currencyCode: "NZD",
        upperCase: false,
        lowerCase: false,
        disabled: false,
        readOnly: false,

        email: false,
        phone: false,
        dollar: false,
        number: false,
        type: null,
        error: null,
        max: null,
    };

    state = {
        text: this.props.text,
        focused: false,
    };

    onTextChange = (e) => {
        let text = e.target.value;

        if (this.props.number || this.props.dollar) {
            text = text.replace(/\D+/g, "");
        }

        if (this.props.dollar) {
            text = toLowestCurrencyFormatter(text, this.props.currencyCode);
        }

        if (this.props.formatOutput) {
            text = this.props.formatOutput(text);
        }

        this.setState({
            text: text,
        });

        if (this.props.onTextChange) {
            this.props.onTextChange(text);
        }

        if (this.props.input) {
            this.props.input.onChange(text);
        }
    };

    onFocus = () => {
        if (this.props.onFocus) {
            this.props.onFocus();
        }

        if (this.props.input) {
            this.props.input.onFocus();
        }

        this.setState({
            focused: true,
        });
    };

    onBlur = () => {
        if (this.props.onBlur) {
            this.props.onBlur();
        }

        if (this.props.input) {
            this.props.input.onBlur();
        }

        this.setState({
            focused: false,
        });
    };

    render() {
        const className = classnames(
            styles.text_input,
            {
                [styles.text_input_focused]: this.state.focused,
            },
            this.props.className,
        );

        let text = this.props.text || this.state.text;

        const props = omit({ ...this.props }, [...Object.keys(TextInput.defaultProps), "input", "meta"]);

        let error = this.props.error;

        if (this.props.input) {
            text = this.props.input.value;
        }

        if (this.props.formatInput) {
            text = this.props.formatInput(text);
        }

        if (this.props.meta) {
            error = this.props.meta.touched && this.props.meta.error;
        }

        if (this.props.upperCase) {
            text = text.toUpperCase();
        }

        if (this.props.lowerCase) {
            text = text.toLowerCase();
        }

        if (this.props.dollar) {
            text = fromLowestCurrencyFormatter(text, this.props.currencyCode);
        }

        const startAdornment = this.props.dollar ? <InputAdornment position="start">$</InputAdornment> : null;
        const endAdornment = this.props.dollar ? (
            <InputAdornment position="end">{this.props.currencyCode.toUpperCase()}</InputAdornment>
        ) : null;

        return (
            <TextField
                {...props}
                className={className}
                label={this.props.label}
                error={!!error}
                helperText={error}
                disabled={this.props.disabled}
                InputProps={{
                    readOnly: this.props.readOnly,
                    startAdornment: startAdornment,
                    endAdornment: endAdornment,
                }}
                onFocus={this.onFocus}
                onBlur={this.onBlur}
                value={text}
                onChange={this.onTextChange}
                type={this.props.type}
            />
        );
    }
}
