import * as Type from "../actions/dataPackInstances";

const initialState = {};

export default function dataPackInstances(state = initialState, action = { type: null, items: [] }) {
    switch (action.type) {
        case Type.SET_DATA_PACK_INSTANCES: {
            const newState = { ...state };
            action.items.forEach((item) => {
                newState[item.id] = item;
            });
            return newState;
        }

        default:
            return state;
    }
}
