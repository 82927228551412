/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 23/11/18.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

import React, { Component } from "react";
import PropTypes from "prop-types";
import MUIDataTable from "mui-datatables";
import Loader from "../loader/Loader";
import omit from "lodash/omit";
import { withStyles } from "@material-ui/core/styles";

const styles = () => ({
    root: {
        minWidth: 483, //prevents layout from going wonky on small screen
    },
});

class Table extends Component {
    static propTypes = {
        className: PropTypes.string,
        loading: PropTypes.bool,

        /** Title of the table */
        title: PropTypes.string.isRequired,
        /** Data used to describe table */
        data: PropTypes.array.isRequired,
        /** Columns used to describe table */
        columns: PropTypes.PropTypes.array.isRequired,
        /** Options used to describe table */
        options: PropTypes.shape({
            responsive: PropTypes.oneOf(["stacked", "scroll"]),
            filterType: PropTypes.oneOf(["dropdown", "checkbox", "multiselect", "textField"]),
            textLabels: PropTypes.object,
            pagination: PropTypes.bool,
            customToolbar: PropTypes.oneOfType([PropTypes.func, PropTypes.element]),
            customToolbarSelect: PropTypes.oneOfType([PropTypes.func, PropTypes.element]),
            customFooter: PropTypes.oneOfType([PropTypes.func, PropTypes.element]),
            onRowClick: PropTypes.func,
            resizableColumns: PropTypes.bool,
            selectableRows: PropTypes.bool,
            serverSide: PropTypes.bool,
            onTableChange: PropTypes.func,
            caseSensitive: PropTypes.bool,
            rowHover: PropTypes.bool,
            fixedHeader: PropTypes.bool,
            page: PropTypes.number,
            count: PropTypes.number,
            filterList: PropTypes.array,
            rowsSelected: PropTypes.array,
            rowsPerPage: PropTypes.number,
            rowsPerPageOptions: PropTypes.array,
            filter: PropTypes.bool,
            sort: PropTypes.bool,
            customSort: PropTypes.func,
            search: PropTypes.bool,
            print: PropTypes.bool,
            viewColumns: PropTypes.bool,
            download: PropTypes.bool,
            downloadOptions: PropTypes.shape({
                filename: PropTypes.string,
                separator: PropTypes.string,
            }),
        }),
    };

    static defaultProps = {
        className: null,
        loading: false,
        title: "",
        options: {},
        data: [],
        columns: [],
    };

    render() {
        const { classes } = this.props;

        const props = omit(this.props, "data", "options");
        let data = this.props.data;

        const options = {
            filterType: "dropdown",
            print: false,
            selectableRows: false,
            textLabels: {
                body: {
                    noMatch: this.props.loading ? <Loader large /> : "Sorry, there is no matching data to display",
                },
            },
            ...this.props.options,
            rowsPerPageOptions: [10],
        };

        if (this.props.loading) {
            data = [];
        }

        return (
            <div className={classes.root}>
                <MUIDataTable {...props} data={data} options={options} />
            </div>
        );
    }
}

export default withStyles(styles, { withTheme: true })(Table);
