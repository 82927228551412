import React, { Component } from "react";
import PropTypes from "prop-types";
import styles from "./ChipInput.module.scss";
import classnames from "classnames";
import ChipInput from 'material-ui-chip-input';

export default class MyChipInput extends Component {
    static propTypes = {
        className: PropTypes.string,
        value: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.arrayOf(PropTypes.number)]),
        label: PropTypes.string,
        onValueChange: PropTypes.func,
        disabled: PropTypes.bool,
        readOnly: PropTypes.bool,
        error: PropTypes.string,
    };

    static defaultProps = {
        className: null,
        value: [],
        label: "",
        disabled: false,
        readOnly: false,
        error: null,
    };

    state = {
        value: this.props.value,
        focused: false,
    };

    getValue = () => {
        return this.props.input
            ? this.props.input.value
            : this.props.value || this.state.value;
    }

    onChange = (value) => {
        console.log("onChange", value);
    };

    onAdd = (added) => {
        const value = [
            ...this.getValue(),
            added
        ];
        this.setState({
            value: value
        });
        if (this.props.onValueChange) {
            this.
            this.props.onValueChange(value);
        }
        if (this.props.input) {
            this.props.input.onChange(value);
        }
    };

    onDelete = (deleted) => {
        const value = this.getValue()
        if(!Array.isArray(value)) {
            return;
        }
        const nonDeleted = this.getValue().filter(x => x !== deleted);
        this.setState({
            value: nonDeleted
        });
        if (this.props.onValueChange) {
            this.props.onValueChange(nonDeleted);
        }
        if (this.props.input) {
            this.props.input.onChange(nonDeleted);
        }
    };

    render() {
        const className = classnames(
            styles.root,
            {
                [styles.text_input_focused]: this.state.focused,
            },
            this.props.className,
        );

        let value = this.props.value || this.state.value;

        const props = { ...this.props };

        let error = this.props.error;

        if (this.props.input && this.props.input.value) {
            value = this.props.input.value;
        }
        if (this.props.meta) {
            error = this.props.meta.touched && this.props.meta.error;
        }

        return (
            <ChipInput
                {...props}
                className={className}
                label={this.props.label}
                disabled={this.props.disabled}
                InputProps={{
                    readOnly: this.props.readOnly,
                }}
                value={value}
                onChange={this.onChange}
                onAdd={this.onAdd}
                onDelete={this.onDelete}
            />
        );
    }
}
