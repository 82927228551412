import React from "react";
import Field from "redux-form/es/Field";
import ChipInput from "./ChipInput";

const ChipInputField = (props) => <Field {...props} component={ChipInput} />;

ChipInputField.propTypes = ChipInput.propTypes;
ChipInputField.defaultProps = ChipInput.defaultProps;

export default ChipInputField;
