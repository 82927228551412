/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 2018-12-13.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

import getToken from "./helpers/getAuthToken";
import get from "./api/util/get";
import put from "./api/util/put";
import del from "./api/util/del";

export const SET_WIFI_JOURNEY = "SET_WIFI_JOURNEY";
export const SET_WIFI_JOURNEYS = "SET_WIFI_JOURNEYS";

export const getWifiJourney = (id) => {
    return async (dispatch) => {
        const token = await getToken();
        const wifiJourney = await get(`v1/wifi-journeys/${id}`, token);
        dispatch({
            type: SET_WIFI_JOURNEY,
            wifiJourney: wifiJourney,
        });
        return wifiJourney;
    };
};

export const getWifiJourneys = (params) => {
    return async (dispatch) => {
        const token = await getToken();
        const result = await get("v1/wifi-journeys", token, params);
        dispatch({
            type: SET_WIFI_JOURNEYS,
            items: result.items,
        });
        return result;
    };
};

export const startWifiJourney = (accessPointId, params) => {
    return async (dispatch) => {
        const token = await getToken();
        const wifiJourney = await put(`v1/access-points/${accessPointId}/wifi-journeys`, token, params);
        dispatch({
            type: SET_WIFI_JOURNEY,
            wifiJourney: wifiJourney,
        });
        return wifiJourney;
    };
};

export const endWifiJourney = (accessPointId) => {
    return async (dispatch) => {
        const token = await getToken();
        const wifiJourney = await del(`v1/access-points/${accessPointId}/wifi-journeys`, token);
        dispatch({
            type: SET_WIFI_JOURNEY,
            wifiJourney: wifiJourney,
        });
        return wifiJourney;
    };
};
