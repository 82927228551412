/**

 ▬▬ι═══════ﺤ            -═══════ι▬▬
 Created by chris on 16/05/17.
 ▬▬ι═══════ﺤ            -═══════ι▬▬

 **/

import React, { Component } from "react";
import GoogleMapsMarker from "react-google-maps/lib/components/Marker";
import OverlayView from "./OverlayView";

export default class Marker extends Component {
    render() {
        const markerProps = { ...this.props };
        delete markerProps.src;
        delete markerProps.anchor;
        delete markerProps.origin;
        delete markerProps.scaledSize;
        delete markerProps.size;
        delete markerProps.labelOrigin;
        delete markerProps.lat;
        delete markerProps.lng;

        let icon = null;

        if (this.props.src) {
            icon = {
                url: this.props.src,
                anchor: this.props.anchor,
                origin: this.props.origin,
                scaledSize: this.props.scaledSize,
                size: this.props.size,
                labelOrigin: this.props.labelOrigin,
            };

            return (
                <GoogleMapsMarker
                    icon={icon}
                    position={{
                        lat: this.props.lat,
                        lng: this.props.lng,
                    }}
                    {...markerProps}
                />
            );
        }

        return (
            <OverlayView
                key={this.props.location.id}
                position={{
                    lat: this.props.lat,
                    lng: this.props.lng,
                }}
                mapPaneName={OverlayView.MAP_PANE}
                mapHolderRef={this.props.mapHolderRef}
                {...markerProps}>
                {this.props.children}
            </OverlayView>
        );
    }
}
