import React, { Component } from "react";
import styles from "./LinkButton.module.scss";
import Button from "./Button";
import { Link } from "react-router-dom";
import Hidden from "@material-ui/core/Hidden";

export default class LinkButton extends Component {
    render = () => {
        return (
            <div>
                <Hidden mdUp implementation="css">
                    <Link className={styles.link} {...this.props} />
                </Hidden>
                <Hidden smDown implementation="css">
                    <Link className={styles.link} {...this.props}>
                        <Button {...this.props} />
                    </Link>
                </Hidden>
            </div>
        );
    };
}
