/**

 ▬▬ι═══════ﺤ            -═══════ι▬▬
 Created by Chris on 17/09/18.
 ▬▬ι═══════ﺤ            -═══════ι▬▬

 **/

import { store } from "../App";
import isSysAdmin from "./isSysAdmin";

export default function isAdmin(user) {
    if (!user) {
        user = store.getState().user;
    }

    return isSysAdmin(user) || (user || { roles: [] }).roles.includes("administrator");
}
