/**

 ▬▬ι═══════ﺤ            -═══════ι▬▬
 Created by Chris on 22/08/18.
 ▬▬ι═══════ﺤ            -═══════ι▬▬

 **/

import React, { Component, Suspense } from "react";
import connect from "react-redux/es/connect/connect";
import styles from "./Main.module.scss";
import Route from "react-router-dom/es/Route";
import Switch from "react-router/es/Switch";
import Routes from "../constants/routes/Routes";
import Login from "../components/routes/login/Login";
import DashboardWrapper from "../components/widgets/dashboardWrapper/DashboardWrapper";
import isAdmin from "../constants/isAdmin";
import Loader from "../components/widgets/loader/Loader";
import renderRoutes from "../constants/renderRoutes";

class Main extends Component {
    render() {
        return (
            <div className={styles.main_container}>
                <div className={styles.main_app_container}>
                    {this.props.isLoggedIn ? (
                        <Suspense fallback={<Loader />}>
                            <DashboardWrapper {...this.props}>
                                <Switch location={this.props.location}>{renderRoutes(Routes, this.props.user)}</Switch>
                            </DashboardWrapper>
                        </Suspense>
                    ) : (
                        <Switch location={this.props.location}>
                            <Route path={"*"} component={Login} key={1} />
                        </Switch>
                    )}
                </div>
            </div>
        );
    }
}

export default Main = connect((state) => {
    const user = state.user;
    return {
        isLoggedIn: !!user,
        user: user,
        isAdmin: isAdmin(user),
    };
})(Main);
